export const colorMap = new Map([
    ["accent 1", "accent"],
    ["accent 2", "accent-2"],
    ["accent", "accent"],
    ["dark", "dark"],
    ["error color", "error"],
    ["gray", "gray"],
    ["grey color", "grey"],
    ["light grey", "gray"],
    ["primary color", "primary"],
    ["primary 550", "primary-550"],
    ["primary", "primary"],
    ["secondary color", "secondary"],
    ["secondary", "secondary"],
    ["success color", "success"],
    ["transparent color", "transparent"],
    ["warning color", "warning"],
    ["white", "white"],
]);
