import React from "react";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { Icon, jnjError } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import classnames from "classnames";

import style from "./style.module.scss";

interface Props {
    errorMessage: string;
    className?: string;
}

export const InputError = (props: Props) => {
    const { errorMessage, className } = props;

    return errorMessage ? (
        <div className={classnames(style.errorMessage, className)}>
            <Icon icon={jnjError} size="xs" color="error" verticalAlignMiddle />
            <Typography variant="helperText" color="error">
                {errorMessage}
            </Typography>
        </div>
    ) : null;
};
