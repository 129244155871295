import React from "react";

import style from "./style.module.scss";
import { Sizes, Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import classnames from "classnames";
import { Colors } from "@atoms/Typography/Typography.js";

type LinkVariants = "label-01" | "label-02" | "link" | "link-03";
interface PropTypes {
    children?: string | JSX.Element | JSX.Element[];
    className?: string;
    size?: Sizes;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    variant?: LinkVariants;
    color?: Colors;
}

export const LinkButton = (props: PropTypes): JSX.Element => {
    const { children, className, size, variant = "link", color, ...other } = props;

    return (
        <button className={classnames(style.button, className)} {...other}>
            <Typography variant={variant} size={size} color={color}>
                {children}
            </Typography>
        </button>
    );
};
