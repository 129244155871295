import { NotificationImpression } from "@jmc/core/src/components/NotificationImpression/NotificationImpression";
import { jnjError } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { SnackBar } from "@jmc/solid-design-system/src/components/atoms/SnackBar/SnackBar";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import { mdiAlertCircleOutline } from "@mdi/js";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import style from "./style.module.scss";

interface PropTypes {
    message: string | JSX.Element;
    code?: string;
    closeAfterSec?: number;
    wrapperPosition?: "fixed" | "sticky"; // When rendering within a dialog, it should be fixed.
    onClose?: () => void;
    onSignIn?: () => void;
}

export const ErrorMessage: React.FunctionComponent<PropTypes> = ({
    message,
    code = "",
    closeAfterSec = 0,
    wrapperPosition = "sticky",
    onClose,
    onSignIn,
}: PropTypes): JSX.Element => {
    const [open, setOpen] = useState(true);
    const [action, setAction] = useState(null);
    const { jnjFullBranded } = useJnjBranding();
    const { t } = useTranslation();

    const timer = useRef(null);

    useEffect(() => {
        if (closeAfterSec > 0) {
            timer.current = setTimeout(() => {
                setOpen(false);
                setAction("disappeared over time");
                onClose && onClose();
            }, closeAfterSec * 1000);
        }
    }, []);

    useEffect(() => {
        if (action) {
            clearTimeout(timer.current);
        }
    }, [action]);
    const component = (
        <NotificationImpression
            type="error-message"
            trigger={code === "MarketingTokenExpired" || code === "AccessTokenExpired" ? "time" : "user-action"}
            name={code}
            action={action}
            checkVisibility={false}
        >
            {open && (
                <div className={style.error}>
                    <SnackBar
                        onClose={(): void => {
                            setOpen(false);
                            setAction("user closed");
                            onClose && onClose();
                        }}
                        color={jnjFullBranded ? "dark" : "error"}
                        onSignIn={onSignIn}
                        signinLabel={t("Sign in", { ns: "common" })}
                    >
                        <SnackBar.Icon
                            icon={jnjFullBranded ? jnjError : mdiAlertCircleOutline}
                            data-test-id="SnackBar.Icon"
                        />
                        <SnackBar.Text text={message} data-test-id="SnackBar.Text" />
                    </SnackBar>
                </div>
            )}
        </NotificationImpression>
    );

    return jnjFullBranded ? (
        <div className={style.wrapper} style={{ position: wrapperPosition }}>
            {component}
        </div>
    ) : (
        component
    );
};

export default ErrorMessage;
