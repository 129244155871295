import React from "react";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { useTranslation } from "react-i18next";

import style from "./style.module.scss";

/**
 * If your form has required fields, and you are using the <InputLabel> or <InputContainer> components
 * you can use this component at the end to display a message that is accessible.
 */
export const InputRequiredMessage = () => {
    const { t } = useTranslation();

    return (
        <div className={style.requiredMessage}>
            <Typography font="text" component="p" size="m" id="required-description">
                <span aria-hidden="true">*</span> {t("This is a required field", { ns: "common" })}
            </Typography>
        </div>
    );
};
