import { Color, Variant } from "./HeaderHero";

export const getHeaderStyles = (color, image, variant) => {
    let finalVariant = variant ?? Variant.CONTENT;
    if (finalVariant === Variant.CONTENT && !image) {
        finalVariant = Variant.MEDIA_LARGE;
    }

    let finalColor = Color.DEFAULT;
    if (Object.values(Color).includes(color)) {
        finalColor = color;
    }

    if (finalVariant === Variant.CONTENT && !color) {
        finalColor = Color.GRAY;
    }

    return { finalColor, finalVariant };
};

export const noTopPaddingFirstSection = (firstSectionColor, backgroundColor, variant, image, video, showHeader) => {
    const backgroundColorHeader =
        backgroundColor && Object.values(Color).includes(backgroundColor?.toLowerCase() as Color)
            ? (backgroundColor?.toLowerCase() as Color)
            : undefined;

    const { finalColor, finalVariant } = getHeaderStyles(backgroundColorHeader, image, variant);

    return showHeader && !video && finalColor === firstSectionColor && (finalVariant !== "mediaLarge" || !image);
};
