import { useLocale } from "@jmc/core/src/hooks/useLocale";
import { ApplicationState } from "@redux/modules";
import { ValidAuthResults } from "@redux/modules/authValid";
import { AuthProviders } from "@types/Auth";
import isEqual from "lodash/isEqual";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useReadLocalStorage } from "usehooks-ts";
import yn from "yn";

import { login, loginAnonymously, register } from "../../../auth/auth";
import { CountrySelector } from "../CountrySelector/CountrySelector";

interface Props {
    value: "Login" | "Register" | "LoginAnonymously";
    url?: string;
    provider?: string;
    onCancel?: () => void;
    inPlace?: boolean;
}

export const LoginComponent = ({
    value = "Register",
    url = null,
    provider = yn(process.env.GATSBY_CIAM2_AS_DEFAULT) ? AuthProviders.okta_hcp : AuthProviders.hcp,
    onCancel,
    inPlace = false,
}: Props): JSX.Element => {
    const authData = useSelector(
        (state: ApplicationState) => state.authValid.data as unknown as ValidAuthResults,
        isEqual,
    );
    const [displaySelector, setDisplaySelector] = useState("");
    const countryCode = useReadLocalStorage("countryCode");
    const locale = useLocale();

    const handleLoginOrRegistration = (): void => {
        if (url && !inPlace) {
            //standard case where url is present, the link is medical and so need to open in a new tab

            const asyncLogin = async (): Promise<void> => {
                const response = (await login(countryCode as string, url, provider)) as string;
                if (typeof response === "string") {
                    window.open(response);
                }
            };
            const asyncAnonLogin = async (): Promise<void> => {
                await loginAnonymously(url);
            };
            const asyncRegister = async (): Promise<void> => {
                const response = (await register(countryCode as string, url, provider, locale)) as string;
                if (typeof response === "string") {
                    window.open(response);
                }
            };
            switch (value) {
                case "Login":
                    try {
                        asyncLogin();
                        setDisplaySelector("");
                    } catch (error) {
                        /* eslint-disable no-console */
                        console.error(error);
                    }
                    break;
                case "LoginAnonymously":
                    try {
                        asyncAnonLogin();
                        setDisplaySelector("");
                    } catch (error) {
                        /* eslint-disable no-console */
                        console.error(error);
                    }
                    break;
                case "Register":
                    try {
                        asyncRegister();
                        setDisplaySelector("");
                    } catch (error) {
                        /* eslint-disable no-console */
                        console.error(error);
                    }
                    break;
                default:
                    /* eslint-disable no-console */
                    console.error("UNHANDLED CASE");
                    break;
            }
        } else if (url && inPlace) {
            //special case when coming from the login or register pages
            const asyncLoginInPlace = async (): Promise<void> => {
                const response = (await login(countryCode as string, url, provider)) as string;
                if (typeof response === "string") {
                    window.location.replace(response);
                }
            };
            const asyncAnonLoginInPlace = async (): Promise<void> => {
                await loginAnonymously(url);
            };
            switch (value) {
                case "Login":
                    try {
                        asyncLoginInPlace();
                        setDisplaySelector("");
                    } catch (error) {
                        /* eslint-disable no-console */
                        console.error(error);
                    }
                    break;
                case "LoginAnonymously":
                    try {
                        asyncAnonLoginInPlace();
                        setDisplaySelector("");
                    } catch (error) {
                        /* eslint-disable no-console */
                        console.error(error);
                    }
                    break;
                case "Register":
                    if (authData?.results?.find((r) => r.level === 3)) {
                        onCancel();
                    } else {
                        const asyncRegisterInPlace = async (): Promise<void> => {
                            const response = (await register(countryCode as string, url, provider, locale)) as string;
                            if (typeof response === "string") {
                                window.location.replace(response);
                            }
                        };
                        try {
                            asyncRegisterInPlace();
                            setDisplaySelector("");
                        } catch (error) {
                            /* eslint-disable no-console */
                            console.error(error);
                        }
                    }
                    break;
                default:
                    /* eslint-disable no-console */
                    console.error("UNHANDLED CASE");
                    break;
            }
        } else {
            //normal flow without login
            if (value === "Login") {
                login(countryCode as string, null, provider);
                setDisplaySelector("");
            } else {
                register(countryCode as string, null, provider, locale);
                setDisplaySelector("");
            }
        }
    };

    useEffect(() => {
        if (!countryCode) {
            setDisplaySelector(value);
        } else {
            handleLoginOrRegistration();
        }
    }, [countryCode]);

    if (displaySelector) {
        return (
            <CountrySelector
                url={url}
                ClickAction={displaySelector}
                onCancel={onCancel}
                envVar={
                    provider === AuthProviders.okta_hcp
                        ? process.env.GATSBY_OKTA_CLIENT_IDS
                        : process.env.GATSBY_JANRAIN_CLIENT_IDS
                }
            />
        );
    }

    return null;
};

export default LoginComponent;
