import { ImageQueryResponse } from "@jmc/core/src/types/ImageQueryReponse";
import { Color, Variant } from "@jmc/solid-design-system/src/components/molecules/HeaderHero/HeaderHero";
import {
    CMSBlock,
    CMSCarouselProps,
    CMSEventProps,
    CMSFragmentProps,
    CMSHeroProps,
    CMSPersonProps,
    CMSProductProps,
    CMSSMPCProps,
    CMSSpecialtyProps,
    CombinedCvent,
    IMR,
    QuickEvent,
} from "@types";

import { AccessLevel } from "./AccessLevel";
import { CMSFootnotes } from "./CMSFootnotes";
import { CMSRegulatoryStatus } from "./CMSRegulatoryStatus";
import { CMSSiteMenuItem } from "./CMSShellProps";

export const CMS_HEADER_SOURCE_INHERIT_FROM_SITE = "Inherit from site settings";
export const CMS_HEADER_SOURCE_NONE = "None  (no header on this page)";
export const CMS_HEADER_SOURCE_IMAGE = "Image";
export const CMS_HEADER_SOURCE_COLORED_BACKGROUND = "Colored background";
export const CMS_HEADER_SOURCE_HERO = "Hero component";
export const CMS_HEADER_SOURCE_CAROUSEL = "Carousel";

export const CMS_HEADER_SIZE_SMALL = "Small";
export const CMS_HEADER_SIZE_MEDIUM = "Medium";
export const CMS_HEADER_SIZE_LARGE = "Large";
export const CMS_HEADER_SIZE_EXTRA_SMALL = "Extra Small";

export interface CMSPage {
    title: string;
    page_title: string;
    table_of_content: boolean;
    cvents: CombinedCvent[];
    quickEvents: QuickEvent[];
    seo_settings: {
        meta_title: string;
        meta_description: string;
        page_description: string;
        canonical_tag: string;
        hreflang_associated_url?: string[];
        indexing_and_sitemap: "index" | "noindex" | "default" | null;
        paywall: "inherit" | "on" | "off";
        paywall_teaser: CMSFragmentProps[];
        teaser_imr_code: {
            imr: IMR[];
        };
        twitter?: {
            title?: string;
            description?: string;
            image?: { url: string };
        };
        open_graph?: {
            title?: string;
            description?: string;
            image?: { url: string };
        };
        medical_expert: CMSPersonProps[];
        medical_expert_reviewer: string;
    };
    regulatory_status: CMSRegulatoryStatus;
    uid: string;
    url: string;
    access_control: {
        access_level: AccessLevel;
    };
    header_visual?: {
        header_visual_source:
            | typeof CMS_HEADER_SOURCE_INHERIT_FROM_SITE
            | typeof CMS_HEADER_SOURCE_NONE
            | typeof CMS_HEADER_SOURCE_IMAGE
            | typeof CMS_HEADER_SOURCE_COLOURED_BACKGROUND
            | typeof CMS_HEADER_SOURCE_COLORED_BACKGROUND
            | typeof CMS_HEADER_SOURCE_HERO
            | typeof CMS_HEADER_SOURCE_CAROUSEL;
        header_image_size:
            | typeof CMS_HEADER_SIZE_EXTRA_SMALL
            | typeof CMS_HEADER_SIZE_SMALL
            | typeof CMS_HEADER_SIZE_MEDIUM
            | typeof CMS_HEADER_SIZE_LARGE;
        select_hero: CMSHeroProps[];
        select_carousel: CMSCarouselProps[];
        header_image_from_file: ImageQueryResponse;
        image_focus: { image_focus: string };
        variant: Variant;
        background_color: Color;
    };
    site_reference: CMSFreeFormPageSiteReference[];
    updated_at: string;
    content: [CMSBlock];
    fields: {
        footnotes: CMSFootnotes[];
    };
    imedical_review: {
        imr: IMR[];
    };
    enable_anonymous_login: boolean;
    smpc_reference: CMSSMPCProps[];
    empty_overview_fragment?: {
        title: string;
        body: CMSBlock[];
    }[];
    maintenance_mode: {
        temporary_maintenance_mode: boolean;
    };
    firstSectionColor: Color;
}

export interface CMSFreeFormPageSiteReference {
    title: string;
    miniSiteTitle?: string;
    meta_fields: {
        title_suffix: string;
        site_description?: string;
        paywall: boolean;
        site_paywall_teaser: CMSFragmentProps[];
        site_teaser_imr: {
            imr: IMR[];
        };
        medical_expert: CMSPersonProps[];
    };
    ae_pi_disclaimer: string;
    branding: {
        header_visual: ImageQueryResponse;
        logo: ImageQueryResponse;
        image_focus: { image_focus: string };
    };
    startpage: {
        uid: string;
        url: string;
    }[];
    relation: CMSProductProps[] | CMSSpecialtyProps[] | CMSEventProps[];
    site_menu: CMSSiteMenuItem[];
    imedical_review: {
        imr: IMR[];
    };
    access_control: {
        user_profiles: CMSUserProfile[];
    };
    smpc_reference: CMSSMPCProps[];
    maintenance_mode: {
        temporary_maintenance_mode: boolean;
    };
}
export interface CMSUserProfile {
    ind_type_select: string[];
    international_specialty: string[];
    country?: string[];
}
