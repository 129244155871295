//import styles
import style from "./style.module.scss";

//import libraries
import React, { ReactNode } from "react";
import classnames from "classnames";

import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { Icon } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { IconColorsType } from "../Icon/Icon";
import { Sizes } from "../Typography/Typography";
import { transformSpecialChars } from "../../../utils/specialChars";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

//declare types
export type ButtonSizes = "small" | "medium" | "large" | "xlarge" | "xxlarge";
export type Variants = "contained" | "outlined" | "icon" | "naked";
export type Colors =
    | "primary"
    | "primary-550"
    | "primary-500"
    | "primaryComplex"
    | "secondary"
    | "accent"
    | "accent-2"
    | "success"
    | "warning"
    | "error"
    | "grey"
    | "green"
    | "red"
    | "white"
    | "transparent"
    | "yellow";

//describe our props
export interface PropTypes extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    id?: string;
    startIcon?: string | JSX.Element;
    endIcon?: string;
    iconColor?: IconColorsType;
    accessoryLeft?: JSX.Element;
    accessoryRight?: JSX.Element;
    /** The text that will be displayed inside the button*/
    children?: string | ReactNode;
    /** All the different variants of our button*/
    variant?: Variants;
    /** All the different colors of our button */
    color?: Colors;
    /** All the different sizes of our button */
    size?: ButtonSizes;
    /** Is the button disabled? */
    disabled?: boolean;
    /** Should the button have rounded edges */
    rounded?: boolean;
    /** Should the button be a circle */
    circle?: boolean;
    /** Is our button a floating action button */
    floating?: boolean;
    /** Callback of our OnClick event */
    onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    active?: boolean;
    fullWidth?: boolean;
    justifiedBetween?: boolean;
    tabIndex?: number;
    buttonRef?: MutableRefObject<any>;
    flexibleWidth?: boolean;
    noSpacing?: boolean;
    focusable?: boolean;
    textVariant?: string;
}

const TEXT_SIZE = {
    small: "s",
    medium: "m",
    large: "l",
};

const ICON_SIZE = {
    small: "0.8rem",
    medium: "1rem",
    large: "1.25rem",
    xlarge: "1.5rem",
    xxlarge: "2rem",
};

export const Button = (props: PropTypes) => {
    const {
        startIcon, // TODO: Rebranding: Check use on cleanup. Seems better to use accessofyLeft cause icon can have type=jnj
        endIcon, // TODO: Rebranding: Check use on cleanup. Seems better to use accessoryRight cause icon can have type=jnj
        iconColor = "inherit",
        accessoryLeft,
        accessoryRight,
        children,
        variant = "contained",
        color = "primary",
        size = "medium",
        disabled,
        rounded = true,
        floating,
        onClick,
        circle,
        id,
        active = false,
        fullWidth,
        justifiedBetween = false,
        tabIndex = 0,
        buttonRef,
        flexibleWidth = false,
        noSpacing = false,
        focusable = true,
        className,
        textVariant = "label-01",
        ...other
    } = props;

    const { jnjFullBranded } = useJnjBranding();

    const classNames = classnames(
        style.element,
        style[variant],
        style[`${variant}__${color}`],
        variant !== "icon" ? style[size] : null,
        rounded && !floating ? style[`element__rounded`] : null,
        circle && floating ? style["floating__circle"] : null,
        !circle && rounded && floating ? style[`floating__rounded`] : null,
        floating ? style.floating : null,
        disabled ? style.disabled : null,
        startIcon ? style.withStartIcon : null,
        endIcon ? style.withEndIcon : null,
        accessoryLeft ? style.withAccessoryLeft : null,
        accessoryRight ? style.withAccessoryRight : null,
        active ? style.active : null,
        fullWidth ? style.fullWidth : null,
        justifiedBetween ? style.justifiedBetween : style.justifiedDefault,
        flexibleWidth ? style.flexibleWidth : null,
        noSpacing ? style.noSpacing : null,
        focusable ? style.focusable : null,
        className,
    );

    const transformChildren = transformSpecialChars(children);
    return (
        <button
            aria-label={id}
            className={classNames}
            disabled={disabled}
            id={id}
            onClick={onClick}
            tabIndex={tabIndex}
            ref={buttonRef}
            {...other}
        >
            {accessoryLeft}
            <Typography
                weight={jnjFullBranded ? "400" : "600"}
                color="inherit"
                size={jnjFullBranded ? "medium" : (TEXT_SIZE[size === "xlarge" ? "medium" : size] as Sizes)}
                variant={jnjFullBranded ? textVariant || "label-01" : "body"}
            >
                {startIcon ? (
                    <Icon icon={startIcon} color={iconColor} size={ICON_SIZE[size]} data-test-id={`Button.StartIcon`} />
                ) : null}
                <span className={style.content} data-test-id={`Button.Children.${id}`}>
                    {transformChildren}
                </span>
                {endIcon ? (
                    <Icon
                        icon={endIcon}
                        color={iconColor}
                        size={jnjFullBranded ? "medium" : ICON_SIZE[size]}
                        data-test-id={`Button.EndIcon`}
                    />
                ) : null}
            </Typography>
            {accessoryRight}
        </button>
    );
};
