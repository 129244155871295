import { JMCLink } from "@components/JMCLink/JMCLink";
import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import { CheckBox } from "@jmc/solid-design-system/src/components/atoms/CheckBox/CheckBox";
import { FlexibleGrid } from "@jmc/solid-design-system/src/components/atoms/FlexibleGrid/FlexibleGrid";
import { InputRequiredMessage } from "@jmc/solid-design-system/src/components/atoms/InputRequiredMessage/InputRequiredMessage";
import { Select } from "@jmc/solid-design-system/src/components/atoms/Select/Select";
import { TextField } from "@jmc/solid-design-system/src/components/atoms/TextField/TextField";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { InputContainer } from "@jmc/solid-design-system/src/components/molecules/InputContainer/InputContainer";
import { getCountries } from "@jmc/solid-design-system/src/utils/countries";
import useJnjBranding from "@jmc/utils/hooks/useJnjBranding";
import loadable from "@loadable/component";
import { Profile } from "@redux/modules/profile";
import { CMS_MEDICAL_CONTENT, CMSLegalDocuments, FormFieldOptions, OrderFormConfig } from "@types";
import classnames from "classnames";
import isEqual from "lodash/isEqual";
import React, { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import regexifyString from "regexify-string";

import style from "./order-form.module.scss";

const PhoneNumberInput = loadable(
    () => import("@jmc/solid-design-system/src/components/molecules/PhoneNumberInput/PhoneNumberInput"),
);

export interface OrderFormProps {
    legalDocuments: CMSLegalDocuments;
    orderFormConfig: OrderFormConfig;
}

export const OrderForm = ({ legalDocuments, orderFormConfig }: OrderFormProps): ReactElement => {
    const { profile } = useSelector(
        ({ profile }: { profile: { data: Profile } }) => ({
            profile: profile.data,
        }),
        isEqual,
    );
    const locale = useLocale();

    const { jnjFullBranded } = useJnjBranding();

    const {
        register,
        formState: { errors },
        setValue,
        getValues,
        control,
    } = useFormContext();
    const countries = getCountries();
    const { t } = useTranslation();

    // Rules to check field is hidden or not
    const isTitleHidden = orderFormConfig?.title_rule === FormFieldOptions.DO_NOT_SHOW;
    const isGenderHidden = orderFormConfig?.gender_rule === FormFieldOptions.DO_NOT_SHOW;
    const isTelHidden = orderFormConfig?.telephone_rule === FormFieldOptions.DO_NOT_SHOW;
    const isCountryHidden = orderFormConfig?.country_rule === FormFieldOptions.DO_NOT_SHOW;
    const isAddressHidden = orderFormConfig?.address_rule === FormFieldOptions.DO_NOT_SHOW;

    // Rules to check field is required or not
    const isTitleRequired = orderFormConfig?.title_rule === FormFieldOptions.SHOW_AND_MANDATORY;
    const isGenderRequired = orderFormConfig?.gender_rule === FormFieldOptions.SHOW_AND_MANDATORY;
    const isCountryRequired = orderFormConfig?.country_rule === FormFieldOptions.SHOW_AND_MANDATORY;
    const isAddressRequired = orderFormConfig?.address_rule === FormFieldOptions.SHOW_AND_MANDATORY;
    const isTelRequired = orderFormConfig?.telephone_rule == FormFieldOptions.SHOW_AND_MANDATORY;

    // get field options for title and gender
    const titleOptions = orderFormConfig?.title_options;
    const genderOptions = orderFormConfig?.gender_options;

    const getEmailError = (errorType: string): string => {
        if (errorType === "required") {
            return t("This field is required.", { ns: "common" });
        }
        if (errorType === "pattern") {
            return t("This is not a valid email.", { ns: "common" });
        }
        return;
    };
    const countryCode = profile?.country;

    const formatAgreement = (): (string | JSX.Element)[] => {
        const input = t("I agree to the {{TERMS_AND_CONDITIONS}}", {
            TERMS_AND_CONDITIONS: "[Legal Notice]",
            ns: "common",
        });
        const decorateLink = (type: string): JSX.Element | string => {
            const document =
                legalDocuments.nodes.find((doc) => {
                    return `[${doc.type}]` === type && doc.locale === locale;
                }) ||
                legalDocuments.nodes.find((doc) => {
                    return `[${doc.type}]` === type && doc.locale === "en-us";
                });
            const internal = document?.internal_link?.[0];
            return (
                <JMCLink
                    external={!!document?.link?.href}
                    url={document?.link?.href || internal?.url}
                    commercial={internal?.regulatory_status?.promotional_or_medical !== CMS_MEDICAL_CONTENT}
                    isAllowedOnMedical
                    key={type}
                >
                    <Typography variant="link">{document?.link.title}</Typography>
                </JMCLink>
            );
        };
        return regexifyString({
            pattern: /\[.*?\]/gim,
            decorator: decorateLink,
            input,
        });
    };

    const handlePhoneChange = (value: string): void => {
        setValue("phone", value);
    };

    return jnjFullBranded ? (
        <>
            {!isTitleHidden && (
                <InputContainer
                    id="title"
                    label={t("Title", { ns: "common" })}
                    error={!!errors.title}
                    errorMessage={t("This field is required.", { ns: "common" })}
                    required={isTitleRequired}
                    dataTestId="orderFormTitle"
                >
                    <Controller
                        name="title"
                        control={control}
                        rules={{ required: isTitleRequired }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                placeholder={t("Title", { ns: "common" })}
                                error={!!errors.title}
                                onChange={(val) => field.onChange(typeof val === "string" ? val : val.value)}
                                required={isTitleRequired}
                            >
                                {titleOptions?.map((option) => (
                                    <Select.Option key={option} value={option} selected={getValues("title") === option}>
                                        {option}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    />
                </InputContainer>
            )}
            {!isGenderHidden && (
                <InputContainer
                    id="gender"
                    label={t("Gender", { ns: "common" })}
                    errorMessage={t("This field is required.", { ns: "common" })}
                    error={!!errors.gender}
                    required={isGenderRequired}
                    data-test-id="orderFormGender"
                >
                    <Controller
                        name="gender"
                        control={control}
                        rules={{ required: isGenderRequired }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                placeholder={t("Gender", { ns: "common" })}
                                error={!!errors.gender}
                                onChange={(val) => field.onChange(typeof val === "string" ? val : val.value)}
                            >
                                {genderOptions?.map((option) => (
                                    <Select.Option
                                        key={option}
                                        value={option}
                                        selected={getValues("gender") === option}
                                    >
                                        {option}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    />
                </InputContainer>
            )}

            <InputContainer
                id="firstName"
                label={t("First Name", { ns: "common" })}
                error={!!errors.firstName}
                errorMessage={t("This field is required.", { ns: "common" })}
                required
            >
                <TextField
                    placeholder={t("First Name", { ns: "common" })}
                    name="firstName"
                    {...register("firstName", { required: true })}
                    error={!!errors.firstName}
                />
            </InputContainer>
            <InputContainer
                id="lastName"
                label={t("Last Name", { ns: "common" })}
                error={!!errors.lastName}
                errorMessage={t("This field is required.", { ns: "common" })}
                required
            >
                <TextField
                    placeholder={t("Last Name", { ns: "common" })}
                    name="lastName"
                    {...register("lastName", { required: true })}
                    error={!!errors.lastName}
                />
            </InputContainer>
            <InputContainer
                id="email"
                label={t("Email address", { ns: "common" })}
                error={!!errors.email}
                errorMessage={getEmailError(errors?.email?.type)}
                required
            >
                <TextField
                    placeholder={t("Email", { ns: "common" })}
                    name="email"
                    {...register("email", {
                        required: true,
                        // eslint-disable-next-line no-useless-escape
                        pattern:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                    error={!!errors.email}
                />
            </InputContainer>

            {!isTelHidden && (
                <InputContainer
                    id="phone"
                    label={t("Phone number", { ns: "common" })}
                    dataTestId="orderFormPhone"
                    error={errors?.phone}
                    required={isTelRequired}
                    errorMessage={
                        errors?.phone?.type === "minLength"
                            ? t("must be a valid phone number", { ns: "common" })
                            : t("This field is required.", { ns: "common" })
                    }
                >
                    <PhoneNumberInput
                        name="phone"
                        {...register(
                            "phone",
                            isTelRequired && {
                                required: true,
                                minLength: 8,
                            },
                        )}
                        countryCode={countryCode}
                        handlePhoneChange={handlePhoneChange}
                        gap="spacing-03"
                        required={isTelRequired}
                    />
                </InputContainer>
            )}
            {!isCountryHidden && (
                <InputContainer
                    id="country"
                    label={t("Country", { ns: "common" })}
                    error={!!errors.country}
                    errorMessage={t("This field is required.", { ns: "common" })}
                    required={isCountryRequired}
                    dataTestId="orderFormCountry"
                >
                    <Controller
                        name="country"
                        control={control}
                        rules={{ required: isCountryRequired }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                placeholder={t("Country", { ns: "common" })}
                                error={!!errors.country}
                                onChange={(val) => field.onChange(typeof val === "string" ? val : val.value)}
                            >
                                {countries?.map(({ countryCode, countryName }) => (
                                    <Select.Option
                                        key={countryCode}
                                        value={countryName}
                                        selected={getValues("country") === countryName}
                                    >
                                        {countryName}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    />
                </InputContainer>
            )}
            {!isAddressHidden && (
                <>
                    <InputContainer
                        id="street"
                        label={t("Street", { ns: "common" })}
                        required={isAddressRequired}
                        error={!!errors.street}
                        errorMessage={t("This field is required.", { ns: "common" })}
                        dataTestId="street.field"
                    >
                        <TextField
                            placeholder={t("Street", { ns: "common" })}
                            {...register("street", { required: !!isAddressRequired })}
                            name="street"
                            error={!!errors.street}
                        />
                    </InputContainer>
                    <InputContainer
                        id="number"
                        label={t("Number", { ns: "common" })}
                        required={isAddressRequired}
                        error={!!errors.number}
                        errorMessage={t("This field is required.", { ns: "common" })}
                        dataTestId="address.number.field"
                    >
                        <TextField
                            {...register("number", { required: !!isAddressRequired })}
                            placeholder={t("Number", { ns: "common" })}
                            name="number"
                            error={!!errors.number}
                        />
                    </InputContainer>
                    <InputContainer
                        id="city"
                        label={t("City", { ns: "common" })}
                        error={!!errors.city}
                        errorMessage={t("This field is required.", { ns: "common" })}
                        required={isAddressRequired}
                        dataTestId="city.field"
                    >
                        <TextField
                            {...register("city", { required: !!isAddressRequired })}
                            placeholder={t("City Name", { ns: "common" })}
                            name="city"
                            error={!!errors.city}
                        />
                    </InputContainer>
                    <InputContainer
                        id="postalCode"
                        label={t("Postal code", { ns: "common" })}
                        required={isAddressRequired}
                        error={!!errors.postalCode}
                        errorMessage={t("This field is required.", { ns: "common" })}
                        dataTestId="postal.code.field"
                    >
                        <TextField
                            placeholder={t("Postal code", { ns: "common" })}
                            {...register("postalCode", { required: !!isAddressRequired })}
                            name="postalCode"
                            error={!!errors.postalCode}
                        />
                    </InputContainer>
                </>
            )}

            <InputContainer
                id="terms"
                error={!!errors.terms}
                errorMessage={t("Please agree to the terms of service.", { ns: "common" })}
                fullSize
            >
                <CheckBox name="terms" required={true} {...register("terms", { required: true })}>
                    <Typography color="inherit">{formatAgreement()}</Typography>
                </CheckBox>
            </InputContainer>

            <InputRequiredMessage />
        </>
    ) : (
        <FlexibleGrid spacing={8} minHeight={false} fractions={[1, 1, 1, 1, 1, 1]}>
            {!isTitleHidden && (
                <div
                    className={classnames(isGenderHidden ? style.fullGrid : style.halfGrid)}
                    data-test-id="orderFormTitle"
                >
                    <Typography color="dark">
                        {t("Title", { ns: "common" })}
                        {isTitleRequired && "*"}
                    </Typography>
                    <Controller
                        name="title"
                        control={control}
                        rules={{ required: isTitleRequired }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                placeholder={t("Title", { ns: "common" })}
                                error={!!errors.title}
                                onChange={(val) => field.onChange(typeof val === "string" ? val : val.value)}
                            >
                                {titleOptions?.map((option) => (
                                    <Select.Option key={option} value={option} selected={getValues("title") === option}>
                                        {option}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    />

                    {errors.title && (
                        <Typography variant="helperText" color="error" data-test-id="title_error">
                            {t("This field is required.", { ns: "common" })}
                        </Typography>
                    )}
                </div>
            )}
            {!isGenderHidden && (
                <div className={style.halfGrid} data-test-id="orderFormGender">
                    <Typography color="dark">
                        {t("Gender", { ns: "common" })}
                        {isGenderRequired && "*"}
                    </Typography>
                    <Controller
                        name="gender"
                        control={control}
                        rules={{ required: isGenderRequired }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                placeholder={t("Gender", { ns: "common" })}
                                error={!!errors.gender}
                                onChange={(val) => field.onChange(typeof val === "string" ? val : val.value)}
                            >
                                {genderOptions?.map((option) => (
                                    <Select.Option
                                        key={option}
                                        value={option}
                                        selected={getValues("gender") === option}
                                    >
                                        {option}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    />
                    {errors.gender && (
                        <Typography variant="helperText" color="error" data-test-id="gender_error">
                            {t("This field is required.", { ns: "common" })}
                        </Typography>
                    )}
                </div>
            )}
            <div className={style.halfGrid}>
                <Typography color="dark">{t("First Name", { ns: "common" })}*</Typography>
                <TextField
                    placeholder={t("First Name", { ns: "common" })}
                    name="firstName"
                    {...register("firstName", { required: true })}
                    error={!!errors.firstName}
                />
                {errors.firstName && (
                    <Typography variant="helperText" color="error" data-test-id="firstname_error">
                        {t("This field is required.", { ns: "common" })}
                    </Typography>
                )}
            </div>
            <div className={style.halfGrid}>
                <Typography color="dark">{t("Last Name", { ns: "common" })}*</Typography>
                <TextField
                    placeholder={t("Last Name", { ns: "common" })}
                    name="lastName"
                    {...register("lastName", { required: true })}
                    error={!!errors.lastName}
                />
                {errors.lastName && (
                    <Typography variant="helperText" color="error" data-test-id="lastname_error">
                        {t("This field is required.", { ns: "common" })}
                    </Typography>
                )}
            </div>
            <div className={style.fullGrid}>
                <Typography color="dark">{t("Email", { ns: "common" })}*</Typography>
                <TextField
                    placeholder={t("Email", { ns: "common" })}
                    name="email"
                    {...register("email", {
                        required: true,
                        // eslint-disable-next-line no-useless-escape
                        pattern:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                    error={!!errors.email}
                />
                {errors.email && (
                    <Typography variant="helperText" color="error" data-test-id="email_error">
                        {getEmailError(errors.email.type)}
                    </Typography>
                )}
            </div>
            {!isTelHidden && (
                <div className={style.fullGrid} data-test-id="orderFormPhone">
                    <Typography color="dark">
                        {t("Telephone", { ns: "common" })}
                        {isTelRequired && "*"}
                    </Typography>
                    <PhoneNumberInput
                        name="phone"
                        {...register(
                            "phone",
                            isTelRequired && {
                                required: true,
                                minLength: 8,
                            },
                        )}
                        countryCode={countryCode}
                        handlePhoneChange={handlePhoneChange}
                    />
                    {errors.phone?.type === "required" && (
                        <Typography variant="helperText" color="error" data-test-id="telephone_error">
                            {t("This field is required.", { ns: "common" })}
                        </Typography>
                    )}
                    {errors.phone?.type === "minLength" && (
                        <Typography variant="helperText" color="error" data-test-id="telephone_error">
                            {t("must be a valid phone number", { ns: "common" })}
                        </Typography>
                    )}
                </div>
            )}
            {!isCountryHidden && (
                <div className={style.fullGrid} data-test-id="orderFormCountry">
                    <Typography color="dark">
                        {t("Country", { ns: "common" })}
                        {isCountryRequired ? "*" : null}
                    </Typography>
                    <Controller
                        name="country"
                        control={control}
                        rules={{ required: isCountryRequired }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                placeholder={t("Country", { ns: "common" })}
                                error={!!errors.country}
                                onChange={(val) => field.onChange(typeof val === "string" ? val : val.value)}
                            >
                                {countries?.map(({ countryCode, countryName }) => (
                                    <Select.Option
                                        key={countryCode}
                                        value={countryName}
                                        selected={getValues("country") === countryName}
                                    >
                                        {countryName}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    />
                    {errors.country && (
                        <Typography variant="helperText" color="error" data-test-id="country_error">
                            {t("This field is required.", { ns: "common" })}
                        </Typography>
                    )}
                </div>
            )}
            {!isAddressHidden && (
                <>
                    <div className={style.twoThird} data-test-id="street.field">
                        <Typography color="dark">
                            {t("common:Street")}
                            {isAddressRequired && "*"}
                        </Typography>
                        <TextField
                            placeholder={t("common:Street")}
                            {...register("street", { required: !!isAddressRequired })}
                            name="street"
                            error={!!errors.street}
                        />
                        {errors.street && (
                            <Typography variant="helperText" color="error" data-test-id="street_error">
                                {t("common:This field is required.")}
                            </Typography>
                        )}
                    </div>
                    <div className={style.oneThird} data-test-id="address.number.field">
                        <Typography color="dark">
                            {t("common:Number (+ Bus)")}
                            {isAddressRequired && "*"}
                        </Typography>
                        <TextField
                            {...register("number", { required: !!isAddressRequired })}
                            placeholder={t("common:Number (+ Bus)")}
                            name="number"
                            error={!!errors.number}
                        />
                        {errors.number && (
                            <Typography variant="helperText" color="error" data-test-id="number_error">
                                {t("common:This field is required.")}
                            </Typography>
                        )}
                    </div>
                    <div className={style.twoThird} data-test-id="city.field">
                        <Typography color="dark">
                            {t("common:City")}
                            {isAddressRequired && "*"}
                        </Typography>
                        <TextField
                            {...register("city", { required: !!isAddressRequired })}
                            placeholder={t("common:City Name")}
                            name="city"
                            error={!!errors.city}
                        />
                        {errors.city && (
                            <Typography variant="helperText" color="error" data-test-id="city_error">
                                {t("common:This field is required.")}
                            </Typography>
                        )}
                    </div>
                    <div className={style.oneThird} data-test-id="postal.code.field">
                        <Typography color="dark">
                            {t("common:Postal code")}
                            {isAddressRequired && "*"}
                        </Typography>
                        <TextField
                            placeholder={t("common:Postal code")}
                            {...register("postalCode", { required: !!isAddressRequired })}
                            name="postalCode"
                            error={!!errors.postalCode}
                        />
                        {errors.postalCode && (
                            <Typography variant="helperText" color="error" data-test-id="postalcode_error">
                                {t("common:This field is required.")}
                            </Typography>
                        )}
                    </div>
                </>
            )}
            <div className={style.fullGrid}>
                <Typography variant="helperText">{t("* required fields", { ns: "common" })}</Typography>
            </div>

            <div className={classnames(style.fullGrid, style.checkBox)}>
                <CheckBox name="terms" {...register("terms", { required: true })}>
                    <div data-test-id="termsLabel">
                        <Typography color="inherit">{formatAgreement()}</Typography>
                    </div>
                </CheckBox>
            </div>

            {errors.terms && (
                <div className={style.fullGrid}>
                    <Typography color="error" variant="helperText" data-test-id="terms_error">
                        {t("Please agree to the terms of service.", { ns: "common" })}
                    </Typography>
                </div>
            )}
        </FlexibleGrid>
    );
};
