import { NotificationImpression } from "@jmc/core/src/components/NotificationImpression/NotificationImpression";
import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import { Button, ButtonSizes } from "@jmc/solid-design-system/src/components/atoms/Button/Button";
import { FlexibleGrid } from "@jmc/solid-design-system/src/components/atoms/FlexibleGrid/FlexibleGrid";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { LoadingButton } from "@jmc/solid-design-system/src/components/molecules/LoadingButton/LoadingButton";
import { Modal } from "@jmc/solid-design-system/src/components/molecules/Modal/Modal";
import { BreakPoint, useMediaQuery } from "@jmc/solid-design-system/src/hooks/useMediaQuery/useMediaQuery";
import { getCountry } from "@jmc/solid-design-system/src/utils/countries";
import useJnjBranding from "@jmc/utils/hooks/useJnjBranding";
import loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";
import yn from "yn";

import { CountryDropdown } from "./CountryDropdown";
import style from "./style.module.scss";

const FlagButton = loadable(() => import("./FlagButton"));

interface Props {
    url?: string;
    onCancel?: () => void;
    ClickAction: string;
    provider?: string;
    envVar?: string;
}

export const CountrySelector: React.FunctionComponent<Props> = ({
    onCancel = () => null,
    ClickAction,
    url,
    envVar = process.env.GATSBY_JANRAIN_CLIENT_IDS,
}: Props): JSX.Element => {
    const { t } = useTranslation();
    const [countryName, setCountryName] = useState(null);
    const [loggingIn, setLoggingIn] = useState(false);
    const [registerIn, setRegisterIn] = useState(false);
    const [showError, setShowError] = useState(false);
    const [action, setAction] = useState(null);
    const [countryCodes, setCountryCodes] = useState([]);
    const [countryCode, setCountryCode] = useLocalStorage("countryCode", null);
    const [country, setCountry] = useState(null);
    const clientIds = JSON.parse(envVar);
    const locale = useLocale();
    const currentCountry = locale.split("-")[1];
    const [buttonSize, setButtonSize] = useState<ButtonSizes>("medium");
    const isMobile = useMediaQuery(BreakPoint.md);
    const { jnjFullBranded } = useJnjBranding();

    useEffect(() => {
        if (jnjFullBranded && isMobile) {
            setButtonSize("small");
        }
    }, [jnjFullBranded, isMobile]);

    useEffect(() => {
        setShowError(false);
    }, [country]);

    useEffect(() => {
        const countryCodesFromClients = Object.keys(clientIds);
        const codes = countryCodesFromClients.filter((code) => {
            const splitted = code.split("-");
            const country = splitted[1];

            if (country) {
                if (currentCountry && country.toUpperCase() === currentCountry.toUpperCase()) {
                    return code.toUpperCase() === locale.toUpperCase();
                }
            }
            return true;
        });
        const countryNameFn = (): Array => {
            let countryNameArray = [];
            countryNameArray = codes.map((e) => {
                let cnCode = e;
                if (e.includes("-")) {
                    const splitted = e.split("-");
                    cnCode = splitted[1]?.toUpperCase();
                }
                const countryDetails = getCountry(cnCode.toUpperCase());
                return {
                    code: e,
                    name: countryDetails?.countryName,
                };
            });
            countryNameArray.sort((a, b) => {
                const fa = a.name,
                    fb = b.name;
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            countryNameArray = countryNameArray.map((e) => {
                return e.code;
            });
            return countryNameArray;
        };
        const countryCodesArray = countryNameFn();
        setCountryCodes(countryCodesArray);
    }, [clientIds.length]);

    useEffect(() => {
        if (showError) {
            setAction("no country selected");
        }
    }, [showError]);

    if (countryCodes.length === 1) {
        // auto select country if user has no choice
        if (countryCodes[0] !== countryCode) {
            setCountryCode(countryCodes[0]);
        }
        return null;
    }

    return (
        <Modal
            position={jnjFullBranded ? "center" : "topRight"}
            spacing={jnjFullBranded ? "xxl" : "large"}
            overflow="visible"
            noSnippet
            onClose={
                jnjFullBranded
                    ? () => {
                          setAction("cancel");
                          setTimeout(onCancel, 10);
                      }
                    : null
            }
        >
            <Modal.Title
                title={ClickAction == "Register" ? t("Register", { ns: "common" }) : t("Login", { ns: "common" })}
            />
            <Modal.Content>{t("What country are you visiting this website from?", { ns: "common" })}</Modal.Content>
            <Modal.Content>
                <NotificationImpression trigger="user-action" type="country selector" action={action}>
                    <div className={style.countrySelector_overflow} data-test-id="country-selector">
                        <div className={style.countrySelector_desktop}>
                            <FlexibleGrid
                                fractions={[1, 1, 1, 1, 1, 1]}
                                spacing={0}
                                minHeight={jnjFullBranded ? false : true}
                            >
                                {countryCodes.map((code) => {
                                    let countryCode = code;
                                    let languageCode = "";
                                    if (code.includes("-")) {
                                        const splitted = code.split("-");
                                        countryCode = splitted[1]?.toUpperCase();
                                        if (countryCode.toUpperCase() !== currentCountry?.toUpperCase()) {
                                            languageCode = splitted[0]?.toUpperCase();
                                        }
                                    }
                                    const countryCodeName = getCountry(countryCode.toUpperCase());
                                    return (
                                        <FlagButton
                                            countryCode={countryCode}
                                            key={countryCode}
                                            languageCode={languageCode}
                                            selected={country === code}
                                            onClick={() => {
                                                setCountry(code);
                                                setCountryName(countryCodeName?.countryName);
                                                setAction("set " + countryCodeName?.countryName);
                                            }}
                                        />
                                    );
                                })}
                            </FlexibleGrid>
                        </div>
                        <div className={style.countrySelector_mobile}>
                            <CountryDropdown
                                currentCountry={currentCountry}
                                countryCodes={countryCodes}
                                selectedCountry={country}
                                onChange={(country) => {
                                    setCountry(country);
                                    setAction("set " + countryName);
                                }}
                            />
                        </div>
                    </div>
                </NotificationImpression>
            </Modal.Content>
            <Modal.Actions>
                {!jnjFullBranded && (
                    <>
                        {ClickAction && ClickAction == "Register" && (
                            <LoadingButton
                                id="Register"
                                color="secondary"
                                size={buttonSize as ButtonSizes}
                                loading={registerIn}
                                onClick={async () => {
                                    if (!country) {
                                        return setShowError(true);
                                    }
                                    if (!url) {
                                        setRegisterIn(true);
                                        setAction("confirmed " + countryName);
                                    }
                                    setCountryCode(country);
                                }}
                            >
                                {t("Confirm & Register", { ns: "common" })}
                            </LoadingButton>
                        )}
                        {ClickAction && ClickAction == "Login" && (
                            <LoadingButton
                                id="Login"
                                color="secondary"
                                size={buttonSize as ButtonSizes}
                                loading={loggingIn}
                                disabled={!yn(process.env.GATSBY_LOGIN_DISABLED) ? loggingIn : true}
                                onClick={async () => {
                                    if (!country) {
                                        return setShowError(true);
                                    }
                                    if (!url) {
                                        setLoggingIn(true);
                                        setAction("confirmed " + countryName);
                                    }
                                    setCountryCode(country);
                                }}
                            >
                                {t("Confirm & Login", { ns: "common" })}
                            </LoadingButton>
                        )}
                    </>
                )}
                <Button
                    color={jnjFullBranded ? "primary" : "secondary"}
                    size={buttonSize as ButtonSizes}
                    variant="outlined"
                    onClick={() => {
                        setAction("cancel");
                        setTimeout(onCancel, 10);
                    }}
                >
                    {t("Cancel", { ns: "common" })}
                </Button>
                {jnjFullBranded && (
                    <>
                        {ClickAction && ClickAction == "Register" && (
                            <LoadingButton
                                id="Register"
                                color="primary"
                                size={buttonSize as ButtonSizes}
                                loading={registerIn}
                                onClick={async () => {
                                    if (!country) {
                                        return setShowError(true);
                                    }
                                    if (!url) {
                                        setRegisterIn(true);
                                        setAction("confirmed " + countryName);
                                    }
                                    setCountryCode(country);
                                }}
                            >
                                {t("Confirm & Register", { ns: "common" })}
                            </LoadingButton>
                        )}
                        {ClickAction && ClickAction == "Login" && (
                            <LoadingButton
                                id="Login"
                                color="primary"
                                size={buttonSize as ButtonSizes}
                                loading={loggingIn}
                                disabled={!yn(process.env.GATSBY_LOGIN_DISABLED) ? loggingIn : true}
                                onClick={async () => {
                                    if (!country) {
                                        return setShowError(true);
                                    }
                                    if (!url) {
                                        setLoggingIn(true);
                                        setAction("confirmed " + countryName);
                                    }
                                    setCountryCode(country);
                                }}
                            >
                                {t("Confirm & Login", { ns: "common" })}
                            </LoadingButton>
                        )}
                    </>
                )}
            </Modal.Actions>
            {showError && (
                <Modal.Content>
                    <Typography color="error" size="s" italic>
                        {t("Please select a country", { ns: "errors" })}
                    </Typography>
                </Modal.Content>
            )}
        </Modal>
    );
};
