import { JMCLink } from "@components/JMCLink/JMCLink";
import { Icon, jnjLock } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import Html from "@jmc/solid-design-system/src/components/molecules/Html/Html";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import fileHelper from "@jmc/utils/utils/file-helper";
import { mdiLock } from "@mdi/js";
import { ValidAuthResults } from "@redux/modules/authValid";
import { MenuItemVisibility } from "@types";
import { isAuthorized } from "@utils/authorizer";
import classnames from "classnames";
import React, { useEffect, useState } from "react";

import style from "./style.module.scss";
import { isCyrillictext } from "./utils";

interface PropTypes {
    id: string;
    url: string;
    title: string;
    visibility: MenuItemVisibility;
    authData: ValidAuthResults;
    isFirstLevel?: boolean;
    labelStyle?: boolean;
}

const hasAccess = (url: string, authData: ValidAuthResults): boolean => {
    return isAuthorized(authData, fileHelper.getFileAccessLevel(url), url, null, null, true);
};

const shouldDisplay = (hasAccess: boolean, visibility: MenuItemVisibility): boolean => {
    return hasAccess || visibility === MenuItemVisibility.DISPLAY_ITEM_WITH_LOCK;
};

const FileAssetMenuLink: React.FunctionComponent<PropTypes> = ({
    id,
    url,
    title,
    visibility,
    authData,
    isFirstLevel = false,
    labelStyle = false,
}: PropTypes) => {
    const [displayLink, setDisplayLink] = useState(shouldDisplay(hasAccess(url, authData), visibility));
    const { jnjFullBranded } = useJnjBranding();

    useEffect(() => {
        setDisplayLink(shouldDisplay(hasAccess(url, authData), visibility));
    }, [authData, url]);

    if (!displayLink) {
        return null;
    }

    return (
        <div
            id={id}
            data-test-id={`FileAssetLink.${title}`}
            className={classnames(style.link, !hasAccess(url, authData) ? style.showLock : null)}
            tabIndex={-1}
        >
            <JMCLink
                url={url}
                external={true}
                commercial={true}
                isFileAsset
                fullWidth={!(labelStyle && jnjFullBranded)}
            >
                {labelStyle && jnjFullBranded ? (
                    <Typography variant="label-03-link" link>
                        <Html data-test-id="Link.Title" isCyrillic={isCyrillictext(title)}>
                            {title}
                        </Html>
                    </Typography>
                ) : (
                    <Html data-test-id="Link.Title" isCyrillic={isCyrillictext(title)}>
                        {title}
                    </Html>
                )}
                <Icon
                    icon={jnjFullBranded ? jnjLock : mdiLock}
                    type={jnjFullBranded ? "jnj" : "mdi"}
                    size={jnjFullBranded ? "small" : "xs"}
                    className={classnames(isFirstLevel ? style.firstLevel : null, style.lock)}
                    color={jnjFullBranded ? "text-light" : "text-dark"}
                    data-test-id="Link.Lock"
                    verticalAlignMiddle
                />
            </JMCLink>
        </div>
    );
};

export { FileAssetMenuLink, hasAccess, shouldDisplay };
