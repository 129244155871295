import style from "./style.module.scss";

import React, { createContext, ReactNode } from "react";
import classnames from "classnames";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { Icon } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { Button } from "@jmc/solid-design-system/src/components/atoms/Button/Button";
import { LinkButton } from "@jmc/solid-design-system/src/components/atoms/LinkButton/LinkButton";
import { Variants } from "../Button/Button";
import { mdiClose } from "@mdi/js";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import { useTranslation } from "react-i18next";

export type Colors = "primary" | "secondary" | "success" | "warning" | "error" | "grey" | "green" | "dark" | "white";

interface PropTypes {
    children?: ReactNode;
    /** The text that will be displayed inside the SnackBar*/
    text?: string | ReactNode;
    /** All the different colors of our SnackBar*/
    color?: Colors;
    /** Callback of our OnClick event */
    onClose?: (...args: []) => void;
    onSignIn?: () => void;
    isPopUp?: boolean;
    isFit?: boolean;
    margin?: boolean;
    contentSpacingWidth?: boolean;
    signinLabel?: string;
}

interface ActionsPropTypes {
    children: ReactNode;
}

const SnackBarContext = createContext(null);
const ActionsContext = createContext(null);

const SnackBarIcon = ({
    icon,
    size,
    color,
}: {
    color?: string;
    icon: string | string[];
    size?: "small" | "medium" | "large";
}): JSX.Element => {
    const { jnjFullBranded } = useJnjBranding();
    return (
        <SnackBarConsumer>
            {() =>
                icon && (
                    <span className={style.icon}>
                        <Icon
                            icon={icon}
                            size={size}
                            data-test-id={`SnackBar.Icon.${icon}`}
                            color={color || "inherit"}
                            verticalAlignMiddle={jnjFullBranded}
                        />
                    </span>
                )
            }
        </SnackBarConsumer>
    );
};

const Title = ({ text }: { text: string | null }): JSX.Element => {
    return (
        <SnackBarConsumer>
            {() => (
                <span className={style.title}>
                    <Typography variant="h4" color="inherit" data-test-id={`SnackBar.Title`}>
                        {text}
                    </Typography>
                </span>
            )}
        </SnackBarConsumer>
    );
};

const Text = ({ text }: { text: string | JSX.Element | (string | JSX.Element)[] | null }): JSX.Element => {
    const { jnjFullBranded } = useJnjBranding();
    return (
        <SnackBarConsumer>
            {() => (
                <span className={style.text}>
                    <Typography
                        variant={jnjFullBranded ? "body-03" : "body"}
                        color="inherit"
                        data-test-id={`SnackBar.Text`}
                    >
                        {text}
                    </Typography>
                </span>
            )}
        </SnackBarConsumer>
    );
};

const SnackBarButton = ({
    onClick,
    text,
    color,
    variant,
}: {
    onClick: () => void;
    text: string;
    color?: Colors;
    variant?: Variants;
}): JSX.Element => {
    return (
        <ActionsConsumer>
            {() => (
                <Button onClick={onClick} color={color} variant={variant} data-test-id="SnackBar.Button">
                    {text}
                </Button>
            )}
        </ActionsConsumer>
    );
};

export const Actions = ({ children }: ActionsPropTypes) => {
    return (
        <div className={classnames(style.actions)} data-test-id="SnackBar.Actions">
            <ActionsContext.Provider value={{}}>{children}</ActionsContext.Provider>
        </div>
    );
};

export const SnackBar = ({
    color = "primary",
    onClose,
    onSignIn,
    isPopUp = false,
    children,
    isFit = false,
    margin = true,
    contentSpacingWidth = false,
    signinLabel = null,
}: PropTypes) => {
    const { t } = useTranslation();
    const { jnjFullBranded } = useJnjBranding();

    const snackbarContent = () => {
        return (
            <>
                <SnackBarContext.Provider value={{}}>{children}</SnackBarContext.Provider>
                {!(jnjFullBranded && color === "dark") && Boolean(onClose) && (
                    <button
                        className={style.close}
                        onClick={onClose}
                        data-test-id="SnackBar.CloseButton"
                        title={t("Close", { ns: "common" })}
                    >
                        <Icon icon={mdiClose} data-test-id="SnackBar.CloseIcon" />
                    </button>
                )}
            </>
        );
    };

    return jnjFullBranded && color === "dark" ? (
        <div className={style.wrapper}>
            <div
                className={classnames(
                    style.element,
                    style[`color__${color}`],
                    isFit ? style.isFit : null,
                    isPopUp ? style.popUp : null,
                    margin ? style.margin : null,
                )}
                data-test-id="SnackBar"
                role="alertdialog"
            >
                <div className={style.content}>
                    {contentSpacingWidth ? (
                        <div className={style.contentContainerWidth}>{snackbarContent()}</div>
                    ) : (
                        snackbarContent()
                    )}
                </div>
                <div className={style.closeLink}>
                    {signinLabel && Boolean(onSignIn) && (
                        <LinkButton variant="link-03" data-test-id="SnackBar.SignInButton" onClick={onSignIn}>
                            {signinLabel}
                        </LinkButton>
                    )}
                    <LinkButton variant="link-03" data-test-id="SnackBar.CloseButton" onClick={onClose}>
                        {t("Close", { ns: "common" })}
                    </LinkButton>
                </div>
            </div>
        </div>
    ) : (
        <div
            className={classnames(
                style.element,
                style[`color__${color}`],
                isFit ? style.isFit : null,
                isPopUp ? style.popUp : null,
                margin ? style.margin : null,
            )}
            data-test-id="SnackBar"
        >
            {contentSpacingWidth ? (
                <div className={style.contentContainerWidth}>{snackbarContent()}</div>
            ) : (
                snackbarContent()
            )}
        </div>
    );
};

export const ActionsConsumer = ({ children }: { children: any }) => (
    <ActionsContext.Consumer>
        {(context: object) => {
            if (!context) {
                throw new Error("Actions compound components cannot be rendered outside the Actions component");
            }
            return children(context);
        }}
    </ActionsContext.Consumer>
);

export const SnackBarConsumer = ({ children }: { children: any }) => (
    <SnackBarContext.Consumer>
        {(context: object) => {
            if (!context) {
                throw new Error("SnackBar compound components cannot be rendered outside the SnackBar component");
            }
            return children(context);
        }}
    </SnackBarContext.Consumer>
);

Actions.Button = SnackBarButton;
Actions.displayName = "Actions";

SnackBar.displayName = "SnackBar";
SnackBar.Icon = SnackBarIcon;
SnackBar.Title = Title;
SnackBar.Text = Text;
SnackBar.Button = SnackBarButton;
SnackBar.Actions = Actions;
