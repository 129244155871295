import { Icon, jnjCloseNaked } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { BreakPoint, useMediaQuery } from "@jmc/solid-design-system/src/hooks/useMediaQuery/useMediaQuery";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import { mdiCloseCircleOutline } from "@mdi/js";
import { update } from "@redux/modules/medicalRibbon";
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import style from "./style.module.scss";

export interface MedicalRibbonProps {
    isScientificPublicationPage?: boolean;
}

export const MedicalRibbon = ({ isScientificPublicationPage }: MedicalRibbonProps): JSX.Element => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMediaQuery(BreakPoint.lg);
    const { jnjFullBranded } = useJnjBranding();

    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const getMedicalRibbon = (): void => {
            if (ref.current?.clientHeight > 0) {
                dispatch(update({ height: ref.current?.clientHeight }));
            } else {
                requestAnimationFrame(getMedicalRibbon);
            }
        };
        getMedicalRibbon();

        // Add ResizeObserver to track height changes
        const resizeObserver = new ResizeObserver(() => {
            getMedicalRibbon();
        });

        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []); // Empty dependency array since we're using ResizeObserver

    return jnjFullBranded ? (
        <div
            className={classnames(style.medicalRibbon, isOpen ? null : style.closed)}
            data-test-id="MedicalRibbon"
            onClick={() => !isOpen && setIsOpen(true)}
            ref={ref}
        >
            <div className={style.expandedContent}>
                <div className={style.text}>
                    {isScientificPublicationPage ? (
                        <Typography size="s" data-test-id="MedicalRibbon.FullText">
                            {t(
                                "scientificPublications:As original scientific articles, posters or abstracts that have been published in prestigious and recognized scientific sources and/or literal translations of the same. Following intellectual property laws, this content is for your personal informative use, using it on a different way or distributing parts or the whole of this information/contents to 3rd parties is strictly forbidden.",
                            )}
                        </Typography>
                    ) : (
                        <Typography size="s" data-test-id="MedicalRibbon.FullText">
                            {t(
                                "common:You are viewing the Medical Education content part of our website. The programs and content of the presentations are owned by Scientific Third-Parties and respective Faculty members and do not necessarily reflect the point of view of Janssen",
                            )}
                        </Typography>
                    )}
                </div>
                <div className={style.closeIcon} onClick={() => setIsOpen(false)} data-test-id="MedicalRibbon.Close">
                    <Icon icon={jnjCloseNaked} color="inherit" size="large" />
                </div>
            </div>
            <div
                id="MedicalRibbon.Notification"
                className={style.minimizedContent}
                data-test-id="MedicalRibbon.Notification"
            >
                {isScientificPublicationPage ? (
                    <Typography size="s" variant="span">
                        {t("This section contains non-promotional scientific information", {
                            ns: "scientificPublications",
                        })}
                    </Typography>
                ) : (
                    <Typography size="s" variant="span">
                        {t("You are viewing educational content", { ns: "common" })}
                    </Typography>
                )}
            </div>
        </div>
    ) : (
        <div className={classnames(style.medicalRibbon, isOpen ? null : style.closed)} data-test-id="MedicalRibbon">
            <div className={style.backgroundColorDiv}>
                <div className={style.fullText} onClick={isMobile ? () => setIsOpen(false) : null}>
                    <div className={style.text}>
                        {isScientificPublicationPage ? (
                            <Typography size="s" data-test-id="MedicalRibbon.FullText">
                                {t(
                                    "scientificPublications:As original scientific articles, posters or abstracts that have been published in prestigious and recognized scientific sources and/or literal translations of the same. Following intellectual property laws, this content is for your personal informative use, using it on a different way or distributing parts or the whole of this information/contents to 3rd parties is strictly forbidden.",
                                )}
                            </Typography>
                        ) : (
                            <Typography size="s" data-test-id="MedicalRibbon.FullText">
                                {t(
                                    "common:You are viewing the Medical Education content part of our website. The programs and content of the presentations are owned by Scientific Third-Parties and respective Faculty members and do not necessarily reflect the point of view of Janssen",
                                )}
                            </Typography>
                        )}
                    </div>
                    <div
                        className={style.closeIcon}
                        onClick={() => setIsOpen(false)}
                        data-test-id="MedicalRibbon.Close"
                    >
                        <Icon icon={mdiCloseCircleOutline} color="inherit" size="large" />
                    </div>
                </div>
            </div>
            <div className={style.backgroundColorNotification}>
                <div
                    id="MedicalRibbon.Notification"
                    className={style.notification}
                    onClick={() => setIsOpen(!isOpen)}
                    data-test-id="MedicalRibbon.Notification"
                >
                    {isScientificPublicationPage ? (
                        <Typography size="s" variant="span">
                            {t("This section contains non-promotional scientific information", {
                                ns: "scientificPublications",
                            })}
                        </Typography>
                    ) : (
                        <Typography size="s" variant="span">
                            {t("You are viewing educational content", { ns: "common" })}
                        </Typography>
                    )}
                </div>
            </div>
        </div>
    );
};
