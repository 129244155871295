import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule("eventCertificates", () => `/events/certification`);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface EventCertificate {
    id: string;
    eventNumber: string;
    publicName: string;
    location: string;
    start: string;
    end: string;
}
