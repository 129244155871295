import analyticstracker from "@jmc/analyticstracker";
import { useCookieInteraction } from "@jmc/core/src/hooks/useCookieInteraction/index";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import { getCaseInsensitiveKey } from "@jmc/utils/utils/getCaseInsensitiveKey";
import path from "@jmc/utils/utils/path";
import { useLocation } from "@reach/router";
import { ApplicationState } from "@redux/modules";
import { set as setAnalyticsAction } from "@redux/modules/analytics";
import { ValidAuthResults } from "@redux/modules/authValid";
import { load as loadCodsId } from "@redux/modules/codsId";
import { Event, load as loadEvent } from "@redux/modules/event";
import { load as loadProfile, Profile } from "@redux/modules/profile";
import { LoginTypes, NumericAccessLevel, PageContext } from "@types";
import { useIsProfileLoaded } from "@utils/hooks/useIsProfileLoaded";
import axios from "axios";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import { MeasuredComponentProps, withContentRect } from "react-measure";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "usehooks-ts";
import yn from "yn";

import getPageTags from "../../utils/getPageTags";
import { codsidArray } from "./testCodsId";

export type PropTypes = {
    children: JSX.Element | JSX.Element[];
    context: PageContext;
} & MeasuredComponentProps;

export const PageImpressionForExport: React.FunctionComponent<PropTypes> = (props: PropTypes) => {
    const { children, context, contentRect, measureRef } = props;
    const { page } = context;

    const hasPaywall = (): boolean => {
        if (!yn(process.env.GATSBY_PAYWALL_ENABLED)) return false;
        if (page?.seo_settings?.paywall === "inherit" && page?.site_reference?.[0]?.meta_fields) {
            return page?.site_reference?.[0]?.meta_fields?.paywall;
        }
        if (page?.seo_settings?.paywall === "on") return true;
        return false;
    };

    const hasTeaser = (): boolean => {
        if (
            page?.seo_settings?.paywall === "inherit" &&
            page?.site_reference?.[0]?.meta_fields?.paywall === true &&
            page?.site_reference?.[0]?.meta_fields?.site_paywall_teaser?.[0]?.body
        ) {
            return true;
        }
        if (page?.seo_settings?.paywall === "on" && page?.seo_settings?.paywall_teaser?.[0]?.body) {
            return true;
        }
        return false;
    };

    const getPaywallValue = (): string => {
        if (hasPaywall()) {
            if (hasTeaser()) {
                return "true|teaser";
            } else {
                return "true|noteaser";
            }
        } else {
            return "false";
        }
    };

    const authData = useSelector(
        (state: ApplicationState) => state.authValid.data as unknown as ValidAuthResults,
        isEqual,
    );
    const profile = useSelector((state: ApplicationState) => state.profile.data as unknown as Profile, isEqual);
    const eventState = useSelector(
        (state: ApplicationState) => state.event as unknown as { data: Event; loading: boolean },
        isEqual,
    );
    const codsIdParam = useSelector((state: ApplicationState) => state.codsId.data as unknown, isEqual);
    const [paramId, setParamId] = useLocalStorage("paramId", null);

    const [trackImpression, setTrackImpression] = useState(false);
    const [previousLocation, setPreviousLocation] = useState(null);
    const [elementIdentifier, setElementIdentifier] = useState("user-info");

    const { interacted } = useCookieInteraction();
    const dispatch = useDispatch();
    const profileReadyToBeTracked = useIsProfileLoaded();
    const location = useLocation();
    const height = contentRect?.client?.height;
    // New order of login providers: L3 > Marketing Token > L2
    const { level, loginType, authenticationType, codsId, webId, codsIdIncluded } =
        authData?.results?.find((v) => v.provider === "OKTA_HCP") ||
        authData?.results?.find((v) => v.provider === "JANRAIN_HCP") ||
        authData?.results?.find((v) => v.provider === "PING") ||
        authData?.results?.find((v) => v.provider === "PROVIDED_MARKETING") ||
        authData?.results?.find((v) => v.provider === "PROVIDED_ANONYMOUS") ||
        {};

    // function to allow tracking of the page impression
    const allowTracking = (): void => {
        setTimeout(() => {
            setTrackImpression(true);
        }, 500); // the 500ms makes sure we have the proper height
    };

    //load profile but only if the user is logged in in some ways and doesn't already have a profile
    useEffect(() => {
        if (authData?.results?.length > 0 && !profile) {
            dispatch(loadProfile());
        }
        if (!authData && webIdParam) {
            dispatch(loadCodsId({ webId: webIdParam }));
        }
    }, [authData, profile]);

    //load event
    useEffect(() => {
        if (context.isEvent) {
            dispatch(loadEvent({ id: context.id }));
        }
    }, [context]);

    useEffect(() => {
        if (codsIdParam && webIdParam) {
            setParamId({ webId: webIdParam, codsId: codsIdParam });
        }
    }, [codsIdParam]);

    // only track a page impression when the path has changed or on initial page visit
    useEffect(() => {
        if (!previousLocation || location.pathname !== previousLocation.pathname) {
            allowTracking();
            dispatch(setAnalyticsAction({ pageImpressionTracked: false }));
        }
        setPreviousLocation(location);
    }, [location]);

    // when we're tracking an event we should wait for this event to be loaded to avoid wrong parameters being send with the impression
    const isTrackingReady = (): boolean => {
        if (!context?.isEvent) return true;
        if (context?.isEvent && eventState?.data) return true;
        if (context.isEvent && eventState?.error) return true;
        return false;
    };

    const isReady = isTrackingReady();

    // clear tracking after tracking the page impression
    useEffect(() => {
        if (profileReadyToBeTracked && trackImpression && interacted && isReady) {
            analyticstracker().trackImpression(EventTypes.PAGE_IMPRESSION, { newPage: true });
            setTrackImpression(false);
            dispatch(setAnalyticsAction({ pageImpressionTracked: true }));
        }
    }, [trackImpression, interacted, isReady, profileReadyToBeTracked]);

    useEffect(() => {
        if (
            (!previousLocation || location.pathname !== previousLocation.pathname || location?.key == "initial") &&
            loginType === LoginTypes.PING &&
            isReady &&
            !yn(process.env.GATSBY_DISABLE_EMPLOYEE_LOGIN_LOGGING, { default: false })
        ) {
            const isCommercial = context?.commercial_content;
            if (context?.isEvent) isCommercial == eventState?.data?.medical;
            axios
                .post(
                    path.join(
                        `${process.env.GATSBY_API_ORIGIN_URL}/${process.env.GATSBY_API_VERSION}/`,
                        "/log/track/epa",
                    ),
                    { url: location?.pathname, type: isCommercial ? "201" : "301" },
                )
                .catch((error) => {
                    console.error("Error logging epa tracking", error);
                });
        }
    }, [location, isReady, loginType]);

    const getLoginId = (): string => {
        if (authenticationType === "doccheck" && codsId) {
            return codsId;
        } else if ((level === NumericAccessLevel.level2 && !paramId) || authenticationType === "doccheck") {
            return "anonymous";
        } else if (level === NumericAccessLevel.level3 || loginType === LoginTypes.EA_TOKEN) {
            return codsId || "unset CODSID";
        } else if (!authenticationType && paramId) {
            return paramId?.codsId;
        }
        return "no_login";
    };

    const searchParams =
        typeof window !== `undefined` && Object.fromEntries(new URLSearchParams(window?.location?.search));

    const webIdParam = getCaseInsensitiveKey("webid", searchParams);

    const getLoginType = (): string => {
        if (authenticationType === "doccheck") {
            return "doccheck";
        } else if (loginType === LoginTypes.EA_TOKEN) {
            return "ea-token";
        } else if (level === NumericAccessLevel.level2) {
            return "level2";
        } else if (loginType === LoginTypes.PING) {
            return "employee";
        } else if (level === NumericAccessLevel.level3) {
            return "janrain_2.0";
        } else if (!authenticationType && paramId) {
            return "parameter";
        }
        return "no_login";
    };

    useEffect(() => {
        if (profile) setElementIdentifier("user-login-and-profile-info");
        else if (getLoginId() !== "no_login") setElementIdentifier("user-login-info");
    }, [profile, authenticationType, codsId, level, loginType]);

    const userLevel = loginType === LoginTypes.EA_TOKEN ? 3 : level;

    const pageTags = getPageTags(context, eventState);

    const env = process.env.GATSBY_CONTENTSTACK_ENVIRONMENT;
    const array = codsidArray;

    const getPersona = (): { [k: string]: string } | "unset" => {
        if (profile?.personaSegmentation && profile?.personaSegmentation?.length >= 1) {
            const personaObject: { [k: string]: string } = {};
            for (const i in profile?.personaSegmentation) {
                if (Number(i) === 4) {
                    break;
                }
                personaObject[
                    `persona${Number(i) + 1}`
                ] = `${profile?.personaSegmentation[i]?.product}_${profile?.personaSegmentation[i]?.personaSegment}`;
            }
            return personaObject;
        } else {
            return "unset";
        }
    };

    const trackingInfo: { [k: string]: unknown } = {
        "access-level": userLevel || 1,
        locale: (context?.locale || "").toUpperCase(),
        totalpixelheight: Math.round(height || 0),
        traffic_type: env !== "live" ? "internal" : "unset",
        paywall: getPaywallValue(),
        ...pageTags,
        contenttopic: pageTags?.contenttopic?.value?.toLowerCase() || "unset",
        key_message_group: pageTags?.key_message_group?.value || "unset",
        key_message_sub_group: pageTags?.key_message_sub_group?.value || "unset",
        user: {
            loginid: getLoginId(),
            loginType: getLoginType(),
            country: profile?.country || "unset",
            userSpecialty: profile?.specialty || "unset",
            locale: profile?.locale || "unset",
            individualType: profile?.individualType || "unset",
            internationalSpecialty: profile?.internationalSpecialty || "unset",
            user_type: array.includes(codsId) ? "internal" : "unset",
            persona: getPersona(),
            webId: webId || paramId?.webId || "unset",
            codsId_in_param: codsIdIncluded,
        },
    };

    const siteMaintenance = context?.page?.site_reference?.[0]?.maintenance_mode?.temporary_maintenance_mode;
    const pageMaintenance = context?.page?.maintenance_mode?.temporary_maintenance_mode;

    if (pageMaintenance) trackingInfo.title = "Page under maintenance";
    if (siteMaintenance) trackingInfo.title = "Site under maintenance";
    if (siteMaintenance && pageMaintenance) trackingInfo.title = "Site under maintenance";

    return (
        <div
            ref={measureRef}
            id={elementIdentifier}
            style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}
            role="none"
            data-tracking-event={EventTypes.PAGE_IMPRESSION}
            data-tracking-info={JSON.stringify(trackingInfo)}
        >
            {children}
        </div>
    );
};

export const PageImpression = withContentRect("client")(PageImpressionForExport);
