import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { JMCHtml } from "@components/JMCHtml/JMCHtml";
import { getCaseInsensitiveKey } from "@components/MarketingToken/MarketingToken";
import analyticstracker from "@jmc/analyticstracker";
import { useLocale } from "@jmc/core/src/hooks/useLocale";
import { Button } from "@jmc/solid-design-system/src/components/atoms/Button/Button";
import { Icon, jnjArrowLeft, jnjCloseNaked, jnjDustbin } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { LinkButton } from "@jmc/solid-design-system/src/components/atoms/LinkButton/LinkButton";
import NumberInput from "@jmc/solid-design-system/src/components/atoms/NumberInput/NumberInput";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { LoadingButton } from "@jmc/solid-design-system/src/components/molecules/LoadingButton/LoadingButton";
import useJnjBranding from "@jmc/utils/hooks/useJnjBranding";
import { useOnScreen } from "@jmc/utils/hooks/useOnScreen";
import { mdiArrowLeft, mdiCartOutline, mdiDeleteOutline } from "@mdi/js";
import { mdiAccountCircleOutline, mdiCheckCircleOutline, mdiClose, mdiFileOutline } from "@mdi/js";
import { useLocation } from "@reach/router";
import { ApplicationState } from "@redux/modules";
import { clear, MaterialSetType, set as setMaterialCollection } from "@redux/modules/materialCollection";
import { Profile } from "@redux/modules/profile";
import { RequestMaterialMarketingUrl } from "@redux/modules/requestMaterialMarketingUrl";
import { load as submitRequestMaterialsOrder, RequestMaterialsOrder } from "@redux/modules/requestMaterialsOrder";
import { set as setSalesRepModal } from "@redux/modules/salesRepModal";
import { CMSLegalDocuments, CMSorderFormConfig, MaterialEventType } from "@types";
import { MaterialDeliveryMethod } from "@types";
import materialHelper from "@utils/material-helper";
import trackingHelper from "@utils/tracking-helper";
import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImageProps } from "gatsby-plugin-image";
import isEqual from "lodash/isEqual";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CMSMaterialProps } from "types/CMSMaterialProps";
import { CMSMaterialOrderItem } from "types/CMSRequestMaterials";

import Logo from "../Logo";
import { OrderForm } from "./order-form/order-form";
import style from "./style.module.scss";
export interface Props {
    logo?: {
        url: string;
        gatsbyImageData?: GatsbyImageProps;
    };
}

interface MaterialCollectionHookReturn {
    order: {
        material: CMSMaterialProps;
        amount: number;
        salesRepId?: string;
        businessUnit?: string;
    }[];
    deleteMaterialFromCollection: (
        material: CMSMaterialProps,
        index: number,
        track: boolean,
        callback: () => Promise<void>,
    ) => Promise<void>;
    onChangeMaterialAmount: (item: CMSMaterialOrderItem, amount: number, index: number) => Promise<void>;
    updateCollection: () => Promise<void>;
    deleteCollection: () => Promise<void>;
}

type DialogType = "dialogMaterialForm" | "dialogMaterialThankYou" | "dialogMaterialOrder";

//custom hook for abstracting data manipulation of material collection)
const useMaterialCollection = (): MaterialCollectionHookReturn => {
    const { materialCollection } = useSelector(({ materialCollection }: { materialCollection: MaterialSetType }) => ({
        materialCollection,
    }));

    const [order, setOrder] = useState(Array.from(materialCollection.values()));

    const dispatch = useDispatch();

    useEffect(() => {
        setOrder(Array.from(materialCollection.values()));
    }, [materialCollection]);

    const deleteCollection = async (): Promise<void> => {
        await dispatch(clear());
        return;
    };

    const updateCollection = async (): Promise<void> => {
        await dispatch(setMaterialCollection(materialCollection));
        return;
    };

    const deleteMaterialFromCollection = async (
        material: CMSMaterialProps,
        index: number,
        track = true,
        callback: () => Promise<void>,
    ): Promise<void> => {
        if (track) {
            analyticstracker().trackEvent({
                event: MaterialEventType.MATERIAL_REMOVE_FROM_CART,
                commerce: trackingHelper.mapMaterialImpression(material, "material checkout", index + 1),
                info: { itemtotal: 1 },
            });
        }
        materialCollection.delete(material.id);
        setOrder(Array.from(materialCollection.values()));
        await updateCollection();
        if (materialCollection.size === 0) {
            await callback();
            await deleteCollection();
        }
    };

    const onChangeMaterialAmount = async (item: CMSMaterialOrderItem, amount: number, index: number): Promise<void> => {
        const eventType =
            materialCollection.get(item.material.id).amount < amount
                ? MaterialEventType.MATERIAL_ADD_TO_CART
                : MaterialEventType.MATERIAL_REMOVE_FROM_CART;

        materialCollection.set(item.material.id, {
            material: item.material,
            amount,
            salesRepId: item.salesRepId,
            businessUnit: item.businessUnit,
            deliveryMethod: item.deliveryMethod,
            supplier: item.supplier,
        });
        await updateCollection();

        analyticstracker().trackEvent({
            event: eventType,
            commerce: trackingHelper.mapMaterialImpression(item.material, "material checkout", index, amount),
            info: { itemtotal: 1 },
        });
    };

    return {
        order: order,
        deleteMaterialFromCollection: deleteMaterialFromCollection,
        onChangeMaterialAmount: onChangeMaterialAmount,
        updateCollection: updateCollection,
        deleteCollection: deleteCollection,
    };
};

export const MaterialOverlay = ({ logo }: Props): JSX.Element => {
    const { profile } = useSelector(
        ({ profile }: { profile: { data: Profile } }) => ({
            profile: profile.data,
        }),
        isEqual,
    );
    const requestMaterialMarketingUrl = useSelector(
        (state: ApplicationState) => state.requestMaterialMarketingUrl as RequestMaterialMarketingUrl,
        isEqual,
    );
    const { order, deleteMaterialFromCollection, onChangeMaterialAmount, deleteCollection } = useMaterialCollection();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const scrollArea = useRef(null);
    const locale = useLocale();
    const listRef = useRef(null);
    const formRef = useRef(null);
    const messageRef = useRef(null);
    const buttonContainer = useRef<HTMLDivElement>(null);
    const inViewport = useOnScreen(buttonContainer);
    const location = useLocation();
    const { jnjFullBranded } = useJnjBranding();

    const {
        legalDocuments,
        allOrderFormConfig,
    }: { legalDocuments: CMSLegalDocuments; allOrderFormConfig: CMSorderFormConfig } = useStaticQuery(graphql`
        query LegalDocumentsAndOrderFormQuery {
            legalDocuments: allContentstackLegalDocuments {
                nodes {
                    link {
                        href
                        title
                    }
                    internal_link {
                        url
                        regulatory_status {
                            promotional_or_medical
                        }
                    }
                    title
                    locale
                    type
                }
            }
            allOrderFormConfig: allContentstackRequestMaterials {
                nodes {
                    publish_details {
                        locale
                    }
                    orderFormConfig: order_form_config {
                        address_rule
                        country_rule
                        description
                        gender_options
                        gender_rule
                        telephone_rule
                        title_options
                        title_rule
                    }
                }
            }
        }
    `);

    const localConfig = allOrderFormConfig?.nodes?.filter((node) => node.publish_details.locale === locale);
    const { orderFormConfig = {} } = localConfig[0] || {};

    const closeDialog = async (dialogId: Array<DialogType>): Promise<void> => {
        dialogId.forEach((d) => {
            switch (d) {
                case "dialogMaterialForm":
                    formRef.current.close();
                    break;
                case "dialogMaterialThankYou":
                    messageRef.current.close();
                    break;
                default:
                    listRef.current.close();
                    break;
            }
        });
    };

    const openDialog = async (dialogId: DialogType): Promise<void> => {
        switch (dialogId) {
            case "dialogMaterialForm":
                formRef.current.showModal();
                break;
            case "dialogMaterialThankYou":
                messageRef.current.showModal();
                break;
            default:
                listRef.current.showModal();
                break;
        }
    };

    useEffect(() => {
        if (inViewport) {
            setTimeout(() => {
                analyticstracker().trackEvent({
                    event: MaterialEventType.MATERIAL_CHECKOUT,
                    commerce: order.map((item: CMSMaterialOrderItem) =>
                        trackingHelper.mapMaterialImpression(item.material, "material checkout", 0, item.amount),
                    ),
                    info: { itemtotal: order?.length },
                });
            }, 1000);
        }
    }, [inViewport]);

    useEffect(() => {
        const searchParams =
            typeof window !== `undefined` && Object.fromEntries(new URLSearchParams(window?.location?.search));
        const overlay = getCaseInsensitiveKey("overlay", searchParams);
        const webId = getCaseInsensitiveKey("webId", searchParams);

        const getUidFromURL = (): string => {
            const pathname = location.pathname;
            const parts = pathname.split("/");
            const uid = parts[parts.length - 1];
            return uid;
        };

        const orderRequestedMaterial = (
            requestMaterialMarketingUrlItem: RequestMaterialMarketingUrl,
            uid: string,
        ): void => {
            try {
                const orderMaterialItem = order.find((item) => item.material.uid === uid);
                const amount = orderMaterialItem ? orderMaterialItem.amount : 1;
                const maxAmountOrderable = requestMaterialMarketingUrlItem?.material.maximum_amount_orderable;
                if (amount < maxAmountOrderable) {
                    const materialElementIndex = order.findIndex((item) => item.material.uid === uid);
                    // Order already created
                    if (orderMaterialItem) {
                        onChangeMaterialAmount(
                            orderMaterialItem as CMSMaterialOrderItem,
                            amount + 1,
                            materialElementIndex,
                        );
                    } else {
                        // Create new order
                        const collection = new Map();
                        collection.set(requestMaterialMarketingUrlItem.material.id, {
                            material: requestMaterialMarketingUrlItem.material,
                            amount,
                            salesRep: null,
                        });
                        dispatch(setMaterialCollection(collection));
                    }
                }
                setTimeout(() => {
                    openDialog("dialogMaterialOrder");
                });
            } catch (error: unknown) {
                console.error("MaterialOverlay ", error);
            }
        };

        if (overlay && !webId) {
            openDialog("dialogMaterialOrder");
        } else if (overlay && webId) {
            if (!profile) return;

            const uid = getUidFromURL();
            const requestMaterialMarketingUrlItem = (requestMaterialMarketingUrl as RequestMaterialMarketingUrl[]).find(
                (requestMaterial: RequestMaterialMarketingUrl) => requestMaterial.material.uid === uid,
            ) as RequestMaterialMarketingUrl;
            const isSalesRepDelivery =
                requestMaterialMarketingUrlItem?.material?.order_copies?.delivery_method ===
                MaterialDeliveryMethod.salesrep;

            if (profile.webId === webId || !isSalesRepDelivery) {
                orderRequestedMaterial(requestMaterialMarketingUrlItem, uid);
            } else if (profile.webId !== webId && isSalesRepDelivery) {
                dispatch(setSalesRepModal({ showModal: true }));
            }
        }
    }, [location, profile]);

    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const methods = useForm<MaterialOrderFormData>({
        defaultValues: {
            title: null,
            gender: null,
            firstName: profile?.firstName,
            lastName: profile?.lastName,
            email: profile?.email,
            phone: null,
            street: null,
            number: null,
            city: null,
            postalCode: null,
            country: null,
            terms: false,
        },
    });

    useEffect(() => {
        methods.reset(
            {
                title: null,
                gender: null,
                firstName: profile?.firstName || null,
                lastName: profile?.lastName || null,
                email: profile?.email || null,
                phone: null,
                street: null,
                number: null,
                city: null,
                postalCode: null,
                country: null,
                terms: false,
            },
            { keepDefaultValues: false },
        );
    }, [profile]);

    interface MaterialOrderFormData {
        title: string | null;
        gender: string | null;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phone: string | null;
        street: string | null;
        number: string | null;
        city: string | null;
        postalCode: string | null;
        country: string | null;
        terms: boolean;
    }
    const onSubmit = async (data: MaterialOrderFormData): Promise<void> => {
        setIsLoading(true);
        setErrors([]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((window as any).recap !== false) await recaptchaRef.current.executeAsync();

        if (data?.phone?.length <= 6) data.phone = null;

        delete data.terms;
        const finalData: RequestMaterialsOrder = {
            ...data,
            materialOrders: order.map((item: CMSMaterialOrderItem) => ({
                amount: item.amount,
                key: item.material.display_title || item.material.title,
                type: materialHelper.getMaterialTypeString(item.material.type, t),
                salesRepId: item.salesRepId || null,
                businessUnit: item.businessUnit || null,
                deliveryMethod: item.deliveryMethod || null,
                supplier: item.supplier || null,
            })),
        };

        try {
            const dispatchResult = await dispatch(submitRequestMaterialsOrder({ data: finalData }));
            if (dispatchResult?.type?.includes("success")) {
                analyticstracker().trackEvent({
                    event: MaterialEventType.MATERIAL_PURCHASE,
                    commerce: order.map((item: CMSMaterialOrderItem, index: number) =>
                        trackingHelper.mapMaterialImpression(
                            item.material,
                            "material checkout",
                            index + 1,
                            item.amount,
                        ),
                    ),
                    info: {
                        transaction_id: dispatchResult.payload.referenceId,
                        value: order
                            .reduce((acc, current) => {
                                acc += current.amount;
                                return acc;
                            }, 0)
                            .toFixed(2),
                        itemtotal: order.length,
                    },
                });

                await deleteCollection();

                if (recaptchaRef?.current?.reset) recaptchaRef.current.reset();

                setIsLoading(false);

                await openDialog("dialogMaterialThankYou");
            } else if (dispatchResult?.type?.includes("failure")) {
                const { validationErrors } = dispatchResult.payload;
                if (validationErrors) {
                    setErrors(validationErrors);
                } else {
                    setErrors([
                        { constraints: [dispatchResult.payload.message], property: dispatchResult.payload.errorCode },
                    ]);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error while attempting to dispatch order");
        }
    };

    const header = (dialogListToClose: DialogType[]): JSX.Element => (
        <div className={style.header}>
            <div className={style.logo} data-test-id="Header.Logo">
                <Logo logo={logo} />
            </div>
            <button
                className={style["close-button"]}
                onClick={async () => {
                    await closeDialog(dialogListToClose);
                }}
            >
                <Icon icon={jnjCloseNaked} color="black" verticalAlignMiddle={true} data-test-id="closeIconList" />
            </button>
        </div>
    );

    return jnjFullBranded ? (
        <>
            <dialog
                id="dialogMaterialOrder"
                className={style.dialog}
                data-test-id="dialogMaterialOrder"
                ref={listRef}
                autoFocus
            >
                <div className={style.wrapper}>
                    {header(["dialogMaterialOrder"])}
                    <main>
                        <div className={classNames(style.container, style["order-summary"])}>
                            <div className={style["order-summary__block"]}>
                                <div className={style["order-summary__block__description"]}>
                                    <Typography variant="h3" color="title">
                                        {t("Your free order overview", { ns: "materials" })}
                                    </Typography>

                                    {order.length > 0 && orderFormConfig?.description && (
                                        <JMCHtml
                                            id="form-description"
                                            data-test-id="dialogMaterialOrderDescription"
                                            closeOverlay
                                        >
                                            {orderFormConfig.description}
                                        </JMCHtml>
                                    )}
                                    {order.length === 0 && (
                                        <div className={style["order-empty"]}>
                                            <Typography>
                                                {t("You currently have not items to display", { ns: "materials" })}
                                            </Typography>
                                        </div>
                                    )}
                                </div>

                                {order.length > 0 && (
                                    <div className={style["order-summary__block__list-wrapper"]}>
                                        <div
                                            className={style["order-summary-list"]}
                                            id="materialOverlayList"
                                            data-test-id="materialOverlayList"
                                        >
                                            {order.map((item: CMSMaterialOrderItem, index: number) => (
                                                <>
                                                    <div
                                                        key={item.material.id}
                                                        id={item.material.id}
                                                        className={style["material-item"]}
                                                        data-test-id={item.material.id}
                                                    >
                                                        <div className={style["material-image"]}>
                                                            {item.material.visual ? (
                                                                <img src={item.material.visual.url} />
                                                            ) : (
                                                                <Icon
                                                                    icon={mdiFileOutline}
                                                                    size="xl"
                                                                    color="text-gray-500"
                                                                />
                                                            )}
                                                        </div>
                                                        <div className={style["material-item__details"]}>
                                                            <div className={style["material-title"]}>
                                                                <Typography
                                                                    variant="label-01"
                                                                    color="title"
                                                                    weight="400"
                                                                >
                                                                    <JMCHtml
                                                                        footnotes={item?.material?.fields?.footnotes}
                                                                    >
                                                                        {item.material.display_title ||
                                                                            item.material.title}
                                                                    </JMCHtml>
                                                                </Typography>
                                                            </div>
                                                            <div className={style["material-quantity"]}>
                                                                <div
                                                                    onClick={async (): Promise<void> =>
                                                                        await deleteMaterialFromCollection(
                                                                            item.material,
                                                                            index,
                                                                            true,
                                                                            /* tslint:disable:no-empty */
                                                                            async () => {
                                                                                /* This function is intentionally left blank */
                                                                            },
                                                                        )
                                                                    }
                                                                    data-test-id={`delete.icon.${item.material.id}`}
                                                                >
                                                                    <Icon
                                                                        icon={jnjDustbin}
                                                                        color="primary-550"
                                                                        size="medium"
                                                                        className={style["material-quantity__delete"]}
                                                                    />
                                                                </div>
                                                                <NumberInput
                                                                    key="input"
                                                                    min={1}
                                                                    max={item.material.maximum_amount_orderable}
                                                                    value={item.amount}
                                                                    onChange={async (amount: number): Promise<void> =>
                                                                        await onChangeMaterialAmount(
                                                                            item,
                                                                            amount,
                                                                            index,
                                                                        )
                                                                    }
                                                                    increaseText={t("Increase", { ns: "common" })}
                                                                    decreaseText={t("Decrease", { ns: "common" })}
                                                                    maxAmountText={t("Maximum amount reached", {
                                                                        ns: "common",
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {index < order.length - 1 && (
                                                        <hr className={style["order-summary__hr"]} />
                                                    )}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                className={classNames(
                                    style["action-buttons"],
                                    order.length === 0 && style["flex-start-align"],
                                )}
                            >
                                <LinkButton
                                    color="primary-550"
                                    variant="label-02"
                                    onClick={async () => {
                                        await closeDialog(["dialogMaterialOrder"]);
                                    }}
                                    data-test-id={`button.back.list`}
                                >
                                    <div className={style["action-buttons__back"]}>
                                        <Icon icon={jnjArrowLeft} size="small" color="primary" />
                                        {t("Add more materials", { ns: "materials" })}
                                    </div>
                                </LinkButton>
                                {order.length > 0 && (
                                    <Button
                                        color="primary"
                                        onClick={async (e): Promise<void> => {
                                            e.preventDefault();
                                            await openDialog("dialogMaterialForm");
                                        }}
                                        data-test-id={`button.to.order.page`}
                                        className={style["action-buttons__next"]}
                                    >
                                        {t("Proceed", { ns: "materials" })}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </main>
                </div>
            </dialog>
            <dialog
                id="dialogMaterialForm"
                className={style.dialog}
                data-test-id="dialogMaterialForm"
                ref={formRef}
                autoFocus
            >
                {errors.length > 0 &&
                    errors?.map((error) => (
                        <ErrorMessage
                            key={error.property}
                            message={t(`materials:${Object.values(error.constraints)?.[0] as string}`)}
                            code={error.property}
                            closeAfterSec={5}
                            wrapperPosition="fixed"
                        />
                    ))}
                <div className={style.wrapper}>
                    {header(["dialogMaterialOrder", "dialogMaterialForm"])}
                    <main ref={buttonContainer} data-tracking-event={MaterialEventType.MATERIAL_CHECKOUT}>
                        <div className={style.container}>
                            <FormProvider {...methods}>
                                <form onSubmit={methods.handleSubmit(onSubmit)} className={style.form}>
                                    <div>
                                        <Typography variant="h3" color="title" className={style["form__title"]}>
                                            {t("materials:Your contact details")}
                                        </Typography>
                                        <div className={style["form__input-fields"]}>
                                            <OrderForm
                                                legalDocuments={legalDocuments}
                                                orderFormConfig={orderFormConfig}
                                            />
                                        </div>
                                    </div>
                                    <div className={style["action-buttons"]}>
                                        <LinkButton
                                            color="primary-550"
                                            variant="label-02"
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                await closeDialog(["dialogMaterialForm"]);
                                            }}
                                            data-test-id={`button.backToOrder`}
                                        >
                                            <div className={style["action-buttons__back"]}>
                                                <Icon icon={jnjArrowLeft} size="small" color="primary" />
                                                {t("common:Order overview")}
                                            </div>
                                        </LinkButton>
                                        <LoadingButton
                                            color="primary"
                                            type="submit"
                                            disabled={isLoading}
                                            loading={isLoading}
                                            className={style["action-buttons__next"]}
                                            spinnerColor="interactive"
                                        >
                                            {t("materials:Finalize order")}
                                        </LoadingButton>
                                    </div>
                                </form>
                            </FormProvider>
                        </div>
                    </main>
                </div>
                <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY} ref={recaptchaRef} size="invisible" />
            </dialog>
            <dialog
                id="dialogMaterialThankYou"
                className={style.dialog}
                data-test-id="dialogMaterialThankYou"
                ref={messageRef}
                autoFocus
            >
                <div className={style.wrapper}>
                    {header(["dialogMaterialThankYou", "dialogMaterialForm", "dialogMaterialOrder"])}
                    <main>
                        <div className={classNames(style.container, style["thank-you"])}>
                            <div className={style["thank-you__description"]}>
                                <Typography variant="h3" color="title">
                                    {t("materials:Thank you!")}
                                </Typography>
                                <Typography paragraph>
                                    {t(
                                        "materials:Thank you for choosing Janssen Medical Cloud for ordering your materials.",
                                    )}
                                </Typography>
                            </div>
                            <div className={style["action-buttons"]}>
                                <div />
                                <Button
                                    onClick={async () => {
                                        await closeDialog([
                                            "dialogMaterialThankYou",
                                            "dialogMaterialForm",
                                            "dialogMaterialOrder",
                                        ]);
                                    }}
                                    data-test-id={`button.backToJMC`}
                                    color="secondary"
                                    className={style["action-buttons__next"]}
                                >
                                    {t("materials:Back to JMC")}
                                </Button>
                            </div>
                        </div>
                    </main>
                </div>
            </dialog>
        </>
    ) : (
        <>
            <dialog
                id="dialogMaterialOrder"
                className={style.dialog}
                data-test-id="dialogMaterialOrder"
                ref={listRef}
                autoFocus
            >
                <div className={style.overlayContent}>
                    <div className={style.flexContainer}>
                        <Logo logo={logo} />
                        <div
                            onClick={async () => {
                                await closeDialog(["dialogMaterialOrder"]);
                            }}
                        >
                            <span className={style.closeText} data-test-id="closeTextList">
                                {t("common:Close")}
                            </span>
                            <Icon
                                icon={mdiClose}
                                color="primary"
                                size="large"
                                verticalAlignMiddle={true}
                                data-test-id="closeIconList"
                            />
                        </div>
                    </div>
                    <div className={classNames(style.inner, style.center)}>
                        <span className={style.intro}>
                            <Icon icon={mdiCartOutline} size="medium" verticalAlignMiddle />
                            <Typography variant="h6">{t("Your free order overview", { ns: "materials" })}</Typography>
                        </span>
                        {order.length > 0 && orderFormConfig?.description && (
                            <JMCHtml id="form-description" data-test-id="dialogMaterialOrderDescription" closeOverlay>
                                {orderFormConfig.description}
                            </JMCHtml>
                        )}
                        <hr />
                        <ul
                            id="materialOverlayList"
                            data-test-id="materialOverlayList"
                            ref={scrollArea}
                            className={classNames(order.length === 0 && style.noItem)}
                        >
                            {order.length === 0 && (
                                <Typography>
                                    {t("You currently have not items to display", { ns: "materials" })}
                                </Typography>
                            )}
                            {order.map((item: CMSMaterialOrderItem, index: number) => (
                                <li key={item.material.id} id={item.material.id}>
                                    <div className={style.material} data-test-id={item.material.id}>
                                        {item.material.visual && (
                                            <img src={item.material.visual.url} className={style.image} />
                                        )}
                                        <div className={style.content}>
                                            <JMCHtml footnotes={item?.material?.fields?.footnotes}>
                                                {item.material.display_title || item.material.title}
                                            </JMCHtml>

                                            <div className={style.toolbar}>
                                                <div
                                                    onClick={async (): Promise<void> =>
                                                        await deleteMaterialFromCollection(
                                                            item.material,
                                                            index,
                                                            true,
                                                            /* tslint:disable:no-empty */
                                                            async () => {
                                                                /* This function is intentionally left blank */
                                                            },
                                                        )
                                                    }
                                                    data-test-id={`delete.icon.${item.material.id}`}
                                                >
                                                    <Icon icon={mdiDeleteOutline} color="secondary" size="large" />
                                                </div>
                                                <NumberInput
                                                    key="input"
                                                    min={1}
                                                    max={item.material.maximum_amount_orderable}
                                                    value={item.amount}
                                                    onChange={async (amount: number): Promise<void> =>
                                                        await onChangeMaterialAmount(item, amount, index)
                                                    }
                                                    increaseText={t("Increase", { ns: "common" })}
                                                    decreaseText={t("Decrease", { ns: "common" })}
                                                    maxAmountText={t("Maximum amount reached", {
                                                        ns: "common",
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </li>
                            ))}
                        </ul>
                        <hr />
                        <span className={classNames(style.actions, order.length === 0 && style.noItemBtn)}>
                            <Button
                                startIcon={mdiArrowLeft}
                                variant="naked"
                                justifiedBetween
                                color="primaryComplex"
                                onClick={async () => {
                                    await closeDialog(["dialogMaterialOrder"]);
                                }}
                                data-test-id={`button.back.list`}
                            >
                                {t("Back", { ns: "common" })}
                            </Button>

                            {order.length > 0 && (
                                <div className={style.shrinking}>
                                    <Button
                                        color="secondary"
                                        onClick={async (): Promise<void> => {
                                            await openDialog("dialogMaterialForm");
                                        }}
                                        data-test-id={`button.to.order.page`}
                                    >
                                        {t("Proceed to order", { ns: "materials" })}
                                    </Button>
                                </div>
                            )}
                        </span>
                    </div>
                </div>
            </dialog>
            <dialog
                id="dialogMaterialForm"
                className={style.dialog}
                data-test-id="dialogMaterialForm"
                ref={formRef}
                autoFocus
            >
                {errors.length > 0 &&
                    errors?.map((error) => (
                        <ErrorMessage
                            key={error.property}
                            message={t(`materials:${Object.values(error.constraints)?.[0] as string}`)}
                            code={error.property}
                            closeAfterSec={5}
                        />
                    ))}
                <div className={classNames(style.overlayContent, style.mobile)}>
                    <div className={style.flexContainer}>
                        <Logo logo={logo} />
                        <div
                            onClick={async () => {
                                await closeDialog(["dialogMaterialForm", "dialogMaterialOrder"]);
                            }}
                        >
                            <span className={style.closeText} data-test-id="closeTextForm">
                                {t("common:Close")}
                            </span>
                            <Icon
                                icon={mdiClose}
                                color="primary"
                                size="large"
                                verticalAlignMiddle={true}
                                data-test-id="closeIconForm"
                            />
                        </div>
                    </div>
                    <div
                        className={style.inner}
                        ref={buttonContainer}
                        data-tracking-event={MaterialEventType.MATERIAL_CHECKOUT}
                    >
                        <span className={style.intro}>
                            <Icon icon={mdiAccountCircleOutline} size="medium" verticalAlignMiddle />
                            <Typography variant="h6">{t("materials:Your contact details")}</Typography>
                        </span>
                        <hr />
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <OrderForm legalDocuments={legalDocuments} orderFormConfig={orderFormConfig} />
                                <hr />
                                <span className={style.actions}>
                                    <Button
                                        startIcon={mdiArrowLeft}
                                        variant="naked"
                                        justifiedBetween
                                        color="primaryComplex"
                                        type="button"
                                        onClick={async () => {
                                            await closeDialog(["dialogMaterialForm"]);
                                        }}
                                        data-test-id={`button.backToOrder`}
                                    >
                                        {t("common:Order overview")}
                                    </Button>
                                    <div className={style.shrinking}>
                                        <LoadingButton
                                            color="secondary"
                                            type="submit"
                                            disabled={isLoading}
                                            loading={isLoading}
                                        >
                                            {t("materials:Finalize order")}
                                        </LoadingButton>
                                    </div>
                                </span>
                            </form>
                        </FormProvider>
                    </div>
                </div>
                <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY} ref={recaptchaRef} size="invisible" />
            </dialog>
            <dialog
                id="dialogMaterialThankYou"
                className={style.dialog}
                data-test-id="dialogMaterialThankYou"
                ref={messageRef}
                autoFocus
            >
                <div className={style.overlayContent}>
                    <div className={style.flexContainer}>
                        <Logo logo={logo} />
                        <div
                            onClick={async () => {
                                await closeDialog([
                                    "dialogMaterialThankYou",
                                    "dialogMaterialForm",
                                    "dialogMaterialOrder",
                                ]);
                            }}
                        >
                            <span className={style.closeText} data-test-id="closeTextThankYou">
                                {t("common:Close")}
                            </span>
                            <Icon
                                icon={mdiClose}
                                color="primary"
                                size="large"
                                verticalAlignMiddle={true}
                                data-test-id="closeIconThankYou"
                            />
                        </div>
                    </div>
                    <div className={style.inner}>
                        <span className={style.intro}>
                            <Icon icon={mdiCheckCircleOutline} size="medium" verticalAlignMiddle />
                            <Typography variant="h6">{t("materials:Order completed!")}</Typography>
                        </span>
                        <hr />

                        <Typography variant="h2">{t("materials:Thank you")}</Typography>
                        <Typography paragraph>
                            {t("materials:Thank you for choosing Janssen Medical Cloud for ordering your materials.")}
                        </Typography>

                        <hr />
                        <span className={style.actionsThankYou}>
                            <Button
                                onClick={async () => {
                                    await closeDialog([
                                        "dialogMaterialThankYou",
                                        "dialogMaterialForm",
                                        "dialogMaterialOrder",
                                    ]);
                                }}
                                data-test-id={`button.backToJMC`}
                                color="secondary"
                            >
                                {t("materials:Back to JMC")}
                            </Button>
                        </span>
                    </div>
                </div>
            </dialog>
        </>
    );
};
