import createValueModule from "../create-value-module";

export interface MedicaRibbonState {
    height: number;
}

const { reducer, actions } = createValueModule("medicalRibbon", {
    height: 0,
});

export default reducer;
export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;
