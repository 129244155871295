import analyticstracker from "@jmc/analyticstracker";
import { useLocale } from "@jmc/core/src/hooks/useLocale";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import {
    Language,
    LanguageSwitcher,
} from "@jmc/solid-design-system/src/components/molecules/LanguageSwitcher/LanguageSwitcher";
import { getLanguage } from "@jmc/solid-design-system/src/utils/languages";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import { navigate } from "gatsby";
import React from "react";

interface Props {
    availableLocales: string[];
}

export const LangSwitcherForExport = ({ availableLocales }: Props): JSX.Element => {
    const { jnjFullBranded } = useJnjBranding();
    const currentLocale = useLocale();

    const locales = process.env.GATSBY_LOCALES?.split(",");
    let languages: Language[] = [];

    if (jnjFullBranded) {
        languages =
            locales?.length &&
            locales
                .filter((locale) => availableLocales.includes(locale))
                .map((locale) => {
                    const code = locale.split("-").shift();
                    const lang = getLanguage(code);
                    return { code: code.toUpperCase(), name: lang.name, locale };
                });

        // Avoid showing current language if it's the only one in the list
        if (languages.length === 1 && languages[0].locale === currentLocale) {
            languages = [];
        }
    } else {
        languages =
            locales?.length &&
            locales
                .filter((locale) => locale !== currentLocale)
                .filter((locale) => availableLocales.includes(locale))
                .map((locale) => {
                    const code = locale.split("-").shift();
                    const lang = getLanguage(code);
                    return { code: code.toUpperCase(), name: lang.name, locale };
                });
    }

    const eventTracking = (linkName: string): void => {
        analyticstracker().trackEvent({
            event: EventTypes.NAVIGATION_CLICK,
            info: {
                name: "header_navigation",
                linkname: linkName,
            },
        });
    };

    return (
        <LanguageSwitcher
            currentLocale={currentLocale}
            languages={languages}
            navigate={navigate}
            eventTracking={eventTracking}
        />
    );
};

export const LangSwitcher = React.memo(LangSwitcherForExport);
export default LangSwitcher;
