import { NavigationImpression } from "@components/NavigationImpression/NavigationImpression";
import { useLocale } from "@jmc/core/src/hooks/useLocale";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import Html from "@jmc/solid-design-system/src/components/molecules/Html/Html";
import MegaMenu, { MenuItem, SubMenu } from "@jmc/solid-design-system/src/components/molecules/MegaMenu/MegaMenu";
import { ApplicationState } from "@redux/modules";
import {
    AccessLevel,
    CMS_PROMOTIONAL_CONTENT,
    CMSInterestingMenuLink,
    CMSSiteMenuItem,
    MenuItemVisibility,
} from "@types";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ExternalMenuLink from "./MenuLinks/ExternalMenuLink";
import {
    FileAssetMenuLink,
    hasAccess as hasAccessForFileAsset,
    shouldDisplay as shouldDisplayFileAsset,
} from "./MenuLinks/FileAssetMenuLink";
import {
    hasAccess as hasAccessForInternalLink,
    InternalMenuLink,
    shouldDisplay as shouldDisplayInternalLink,
} from "./MenuLinks/InternalMenuLink";
import JMCInterestingLinks from "./MenuLinks/JMCInterestingLinks";
import { isCyrillictext } from "./MenuLinks/utils";

interface PropTypes {
    siteMenu: CMSSiteMenuItem[];
    interestingLinks: CMSInterestingMenuLink[];
    title: string;
    maintenance: boolean;
    isMinisite: boolean;
    languageSelector: JSX.Element;
}

export const JMCMegaMenu: React.FunctionComponent<PropTypes> = ({
    siteMenu,
    interestingLinks,
    title,
    maintenance,
    isMinisite = false,
    languageSelector,
}: PropTypes) => {
    const locale = useLocale() || "en-us";
    const authValid = useSelector((state: ApplicationState) => state.authValid, isEqual);
    const { t } = useTranslation();

    const getMenuLink = (
        item: CMSSiteMenuItem,
        level: number,
        title?: string,
        linkVisibility?: MenuItemVisibility,
        mobile?: boolean,
    ): JSX.Element => {
        if (item?.internal_page_link?.link?.[0] || item?.internal_page_link?.link_continued) {
            const reference = item.internal_page_link?.link?.[0] ?? {
                page_url: item?.internal_page_link?.link_continued,
                regulatory_status: { promotional_or_medical: CMS_PROMOTIONAL_CONTENT },
                access_control: { access_level: AccessLevel.level1 },
            };
            const visibility = linkVisibility || item.internal_page_link.visibility;
            if (!shouldDisplayInternalLink(hasAccessForInternalLink(reference, authValid.data), visibility)) {
                return;
            }
            return (
                <InternalMenuLink
                    title={title || item?.internal_page_link?.menu_label}
                    reference={reference}
                    visibility={visibility}
                    anchorId={item?.internal_page_link?.anchor_id}
                    params={item?.internal_page_link?.internal_link_parameters?.value}
                    authData={authValid.data}
                    id={`${mobile ? "Mobile." : ""}Internal-${title || item?.internal_page_link?.menu_label}`}
                    isFirstLevel={level === 1}
                />
            );
        } else if (item?.external_link?.link?.href) {
            return (
                <ExternalMenuLink
                    url={item?.external_link?.link?.href}
                    title={title || item?.external_link?.link?.title}
                    id={`${mobile ? "Mobile." : ""}External-${title || item?.external_link?.link?.title}`}
                />
            );
        } else if (item?.file_asset?.file_asset_upload?.url) {
            const url = item.file_asset.file_asset_upload.url;
            const visibility = item.file_asset.visibility;
            if (!shouldDisplayFileAsset(hasAccessForFileAsset(url, authValid.data), visibility)) {
                return;
            }
            return (
                <FileAssetMenuLink
                    url={url}
                    title={title || item?.file_asset?.menu_label}
                    visibility={visibility}
                    authData={authValid.data}
                    id={`${mobile ? "Mobile." : ""}File-Asset-${title || item?.file_asset?.menu_label}`}
                    isFirstLevel
                />
            );
        }
        return null;
    };

    const getMenuItem = (item: CMSSiteMenuItem, i: number, level = 1, title?: string): JSX.Element => {
        const menuLink = getMenuLink(item, level, title);
        if (menuLink) {
            return (
                <MenuItem key={`menuitem.${item?.internal_page_link?.menu_label}.${i}`} level={level}>
                    {menuLink}
                </MenuItem>
            );
        } else if (item?.submenu) {
            const title = item.submenu.menu_label;
            const subMenuItems = item.submenu.menu_item
                .map((item, i) => getMenuItem(item, i, level + 1))
                .filter((item) => !!item);
            if (subMenuItems.length === 0) {
                // If a submenu has no menu items but does contain a link, we render it as a single menu item
                return getMenuItem(item?.submenu?.link, i, level, item?.submenu?.menu_label);
            }
            // A submenu with a level 2 or 3 link can never be hidden. These links will always display with a lock when the user doesn't have access
            const link = getMenuLink(
                item?.submenu?.link,
                level,
                item?.submenu?.menu_label,
                MenuItemVisibility.DISPLAY_ITEM_WITH_LOCK,
            );
            const mobileLink = getMenuLink(
                item?.submenu?.link,
                level,
                item?.submenu?.menu_label,
                MenuItemVisibility.DISPLAY_ITEM_WITH_LOCK,
                true,
            );
            return (
                <SubMenu
                    key={`submenu.${title}.${i}`}
                    id={`Submenu-${title}`}
                    title={
                        <span id={`Submenu-Span-${title}`} data-test-id={`SubMenu.${title}`}>
                            <Html data-test-id="Link.Title" isCyrillic={isCyrillictext(title)}>
                                {title}
                            </Html>
                        </span>
                    }
                    level={level}
                    link={link}
                    mobileLink={mobileLink}
                >
                    {subMenuItems}
                </SubMenu>
            );
        }
        return;
    };

    const getMegaMenu = (): JSX.Element[] => {
        return (siteMenu || []).map((item, i) => getMenuItem(item, i));
    };

    const [megaMenu, setMegaMenu] = useState(getMegaMenu());

    useEffect(() => {
        setMegaMenu(getMegaMenu());
    }, [authValid]);

    if (maintenance) {
        return null;
    }

    return (
        <NavigationImpression
            name={isMinisite ? `site_${title}_navigation` : "default_navigation"}
            type={EventTypes.NAVIGATION_IMPRESSION}
            checkVisibility={false}
        >
            <MegaMenu
                locale={locale}
                interestingLinks={<JMCInterestingLinks interestingLinks={interestingLinks} authData={authValid.data} />}
                languageSelector={languageSelector}
                backLiteral={t("Back", { ns: "navigation" })}
                scrollLeftLiteral={t("Scroll left", { ns: "navigation" })}
                scrollRightLiteral={t("Scroll right", { ns: "navigation" })}
            >
                {megaMenu}
            </MegaMenu>
        </NavigationImpression>
    );
};

export default JMCMegaMenu;
