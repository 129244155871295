import { Color } from "@jmc/solid-design-system/src/components/molecules/HeaderHero/HeaderHero";

import createValueModule from "../create-value-module";

export interface BottomContentState {
    hasVisibleFootnotes?: boolean;
    hasVisibleSMPCs?: boolean;
    hasVisibleIMR?: boolean;
    hasVisibleAEPIDisclaimer?: boolean;
    lastSectionBackground?: Color;
}

const { reducer, actions } = createValueModule("bottomContent", {
    hasVisibleFootnotes: false,
    hasVisibleSMPCs: false,
    hasVisibleIMR: false,
    hasVisibleAEPIDisclaimer: false,
    lastSectionBackground: Color.WHITE,
});

export default reducer;
export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;
