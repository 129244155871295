import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import React from "react";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

import style from "./style.module.scss";

interface Props {
    label: string;
    id?: string;
    htmlFor?: string;
    required?: boolean;
    dataTestId?: string;
}

/**
 * A label for an input field.
 * If you are using mandatory fields in your form, remember to add a <InputRequiredMessage> component at the end of your form.
 */
export const InputLabel = (props: Props) => {
    const { jnjFullBranded } = useJnjBranding();

    const { id = undefined, htmlFor = undefined, label, required, dataTestId = undefined } = props;
    const mandatoryIndicator = `${jnjFullBranded ? "" : " "}*`;

    return (
        <Typography
            color={jnjFullBranded ? "text-01" : "inherit"}
            component="label"
            variant={jnjFullBranded ? "label-02" : "body"}
            weight={jnjFullBranded ? "400" : null}
            htmlFor={htmlFor}
            className={style.inputLabel}
            data-test-id={dataTestId || `InputLabel.${htmlFor || id || "Field"}`}
            id={id}
        >
            {label}
            {required && <span aria-labelledby="required-description">{mandatoryIndicator}</span>}
        </Typography>
    );
};
