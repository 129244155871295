import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { InputLabel } from "@jmc/solid-design-system/src/components/atoms/InputLabel/InputLabel";
import { InputError } from "@jmc/solid-design-system/src/components/atoms/InputError/InputError";
import { Icon, jnjHelp } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { Tooltip } from "@jmc/solid-design-system/src/components/molecules/Tooltip/Tooltip";
import React from "react";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import classnames from "classnames";

import style from "./style.module.scss";

interface Props {
    id?: string;
    label?: string;
    tooltipText?: string;
    helperText?: string;
    errorMessage?: string;
    errorTracking?: JSX.Element;
    error?: boolean;
    required?: boolean;
    dataTestId?: string;
    lastField?: boolean;
    fullSize?: boolean;
    children: JSX.Element;
}

/**
 * Holds any input field in a form, providing label, errors and tooltips.
 *
 * If you are using mandatory fields in your form, remember to add a <InputRequiredMessage> component at the end of your form.
 */
export const InputContainer = (props: Props) => {
    const { jnjFullBranded } = useJnjBranding();

    const {
        id = undefined,
        label = "",
        tooltipText = "",
        helperText = "",
        errorMessage = "",
        error = false,
        errorTracking,
        required,
        dataTestId = undefined,
        lastField = false,
        fullSize = false,
        children,
    } = props;

    return jnjFullBranded ? (
        <div
            className={classnames(
                style.inputContainer,
                lastField ? style.last : null,
                fullSize ? style.fullSize : null,
            )}
            data-test-id={dataTestId || `InputContainer.${id || "Default"}`}
        >
            {label && (
                <div className={style.label}>
                    <InputLabel htmlFor={id} label={label} required={required} />
                    {tooltipText && (
                        <Tooltip text={tooltipText} data-test-id={`Tooltip.Input.${id}`}>
                            <Icon icon={jnjHelp} type="jnj" color="inherit" data-test-id={`Tooltip.Icon`} />
                        </Tooltip>
                    )}
                </div>
            )}
            <div className={style.input}>{children}</div>

            {(helperText || (errorMessage && error)) && (
                <div className={style.extras}>
                    {helperText && !error && (
                        <Typography className={style.helper} variant="helperText" color="light">
                            {helperText}
                        </Typography>
                    )}
                    {errorMessage && error && <InputError errorMessage={errorMessage} />}
                    {errorTracking}
                </div>
            )}
        </div>
    ) : (
        <>{children}</>
    );
};
