/**
 * Find the element with the given anchor id.
 */
const findElement = (anchor: string): HTMLElement => {
    const id = (anchor && anchor.startsWith("#") ? anchor.substring(1) : anchor) || null;
    const element = document.getElementById(id) || document.getElementById(decodeURI(id)) || null;

    return element;
};

/**
 * Scrolls to the anchor with offset of navigation
 * @param anchor id of the anchor
 */
export const anchorScroll = (
    anchor: string | HTMLElement,
    tocOffset?: number,
    preventExpand?: boolean,
    scrollOffset?: number,
): void => {
    const getVisiblePart = (element: HTMLElement): { top: number; height: number } => {
        if (!element) return { top: 0, height: 0 };

        const rect = element.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        const visibleTop = Math.max(0, rect.top);
        const visibleBottom = Math.min(viewportHeight, rect.bottom);

        return {
            top: visibleTop,
            height: visibleBottom - visibleTop,
        };
    };
    const elem = typeof anchor === "string" ? findElement(anchor) : anchor;

    const header = findElement("main-header");
    const headerHeight = getVisiblePart(header).height || 0;
    const tocHeight = tocOffset || findElement("toc-bar")?.offsetHeight || 0;
    const totalHeight = headerHeight + tocHeight - (scrollOffset || 0);

    const style = elem?.getAttribute("style") || "";

    //using scroll into view instead of scroll to to benefit from scroll margin top
    //scroll margin top allow a virtual margin at the top of an element to stop the scrolling going further up
    elem?.setAttribute("style", `${style} scroll-margin-top: ${totalHeight}px;`);

    if (elem?.scrollIntoView) {
        // Wait for the element to be visible before scrolling (E.g: Accordion)
        setTimeout(() => {
            elem?.scrollIntoView({ behavior: "smooth" });
        }, 0);
    }

    const event = new CustomEvent("anchorScrolled", { bubbles: true });
    findElement("main-header")?.dispatchEvent(event);
    if (preventExpand) {
        const collapsed = new CustomEvent("anchorCollapsedScroll", { bubbles: true });
        findElement("main-header")?.dispatchEvent(collapsed);
    }
};

export default anchorScroll;
